.map {
  display: none;
}

.map .state {
  cursor: pointer;
}

.map .state .shape .shape_great .shape_attention .shape_danger {
  cursor: pointer;
  -width: 0;
}

.map .state .label_icon_state {
  fill: #fff;
  font-family: Arial;
  font-size: 12px;
  line-height: 12px;
  font-weight: bold;
}

.map .state .label_state {
  display: none;
  font-family: Arial;
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
}

.map .state:hover .label_state,
.map .state.hover .label_state {
  display: block;
}

.model_green {
  fill: #ffffff;
}

.map .model_green .state .shape {
  fill: #6cb361;
}

.map .model_green .state .shape_great {
  fill: #18641e;
}

.map .model_green .state .shape_attention {
  fill: #FBE433;
}

.map .model_green .state .shape_danger {
  fill: #c31900;
}

.map .model_green .state .shape_very_low {
  fill: #A8A9AD;
}

.map .model_green .state .shape_low {
  fill: #D6EACE;
}

.map .model_green .state .shape_ordinary {
  fill: #93CC2F;
}

.map .model_green .state .shape_good {
  fill: #09AA36;
}

.map .model_green .state .shape_excellent {
  fill: #18641e;
}

.map .model_green .state .icon_state {
  fill: #10592f;
}

.map .model_green .state .icon_state_great {
  fill: #307c0e;
}

.map .model_green .state .icon_state_attention {
  fill: #ac9f1d;
}

.map .model_green .state .icon_state_danger {
  fill: #b81900;
}

.map .model_green .state .icon_state_very_low {
  fill: #A8A9AD;
}

.map .model_green .state .icon_state_low {
  fill: #D6EACE;
}

.map .model_green .state .icon_state_ordinary {
  fill: #93CC2F;
}

.map .model_green .state .icon_state_good {
  fill: #09AA36;
}

.map .model_green .state .icon_state_excellent {
  fill: #055012;
}

.map .model_green .state .label_icon_state {
  fill: black;

}

.map .model_green .state .label_state {
  fill: #666;
}

.map .model_green .state:hover .shape,
.map .model_green .state.hover .shape {
  fill: #12a505;
}

.map .model_green .state:hover .shape_great,
.map .model_green .state.hover .shape_great {
  fill: #198227;
}

.map .model_green .state:hover .shape_attention,
.map .model_green .state.hover .shape_attention {
  fill: #d8cf2c;
}

.map .model_green .state:hover .shape_danger,
.map .model_green .state.hover .shape_danger {
  fill: #da2300;
}

.map .model_green .state:hover .shape_very_low,
.map .model_green .state.hover .shape_very_low {
  fill: #8E8F8F;
}

.map .model_green .state:hover .shape_low,
.map .model_green .state.hover .shape_low {
  fill: #D6E5D1;
}

.map .model_green .state:hover .shape_ordinary,
.map .model_green .state.hover .shape_ordinary {
  fill: #93BA38;
}

.map .model_green .state:hover .shape_good,
.map .model_green .state.hover .shape_good {
  fill: #099C36;
}

.map .model_green .state:hover .shape_excellent,
.map .model_green .state.hover .shape_excellent {
  fill: #18641e;
}

.map .model_green .state:hover .icon_state,
.map .model_green .state.hover .icon_state {
  fill: #2d9835;
}

.map .model_green .state:hover .icon_state_great,
.map .model_green .state.hover .icon_state_great {
  fill: #208f53;
}

.map .model_green .state:hover .icon_state_attention,
.map .model_green .state.hover .icon_state_attention {
  fill: #c5b81f;
}

.map .model_green .state:hover .icon_state_danger,
.map .model_green .state.hover .icon_state_danger {
  fill: #db1a00;
}

.map .model_orange .state .shape {
  fill: #fd7132;
}

.map .model_orange .state .icon_state {
  fill: #6cb361;
}

.map .model_orange .state .label_icon_state {
  fill: #fff;
}

.map .model_orange .state .label_state {
  fill: #666;
}

.map .model_orange .state:hover .shape,
.map .model_orange .state.hover .shape {
  fill: #c93f04;
}

.map .model_orange .state:hover .icon_state,
.map .model_orange .state.hover .icon_state {
  fill: #10592f;
}

.map .model-darkgreen .state .shape {
  fill: #366823;
}

.map .model-darkgreen .state .icon_state {
  fill: #2779c6;
}

.map .model-darkgreen .state .label_icon_state {
  fill: #fff;
}

.map .model-darkgreen .state .label_state {
  fill: #666;
}

.map .model-darkgreen .state:hover .shape,
.map .model-darkgreen .state.hover .shape {
  fill: #4a8c31;
}

.map .model-darkgreen .state:hover .icon_state,
.map .model-darkgreen .state.hover .icon_state {
  fill: #2d9835;
}
