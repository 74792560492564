@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,600;1,400&family=Ranchers&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC",
    "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  font-feature-settings: normal;
  font-variant: normal;
  -moz-osx-font-smoothing: grayscale;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 800;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiCollapse-entered {
  overflow: hidden !important;
}

.ant-select-selection--multiple {
  border: 1px solid #ced4da;
  min-height: 38px !important;
}

.ant-select-selection--multiple .ant-select-selection__placeholder {
  margin-top: -8px;
  margin-left: 6px;
  font-size: 16px;
  color: #6c757d;
}

.ant-select-selection--multiple > ul > li,
.ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
  list-style: none;
  font-size: 16px;
  padding: 2px 20px 2px 5px;
  height: 29px;
}
/* 
.MuiTableCell-body {
  color: red !important;
  font-size: 16px !important;
} */

.map {
  display: none;
}

.map .state {
  cursor: pointer;
}

.map .state .shape .shape_great .shape_attention .shape_danger {
  cursor: pointer;
  -width: 0;
}

.map .state .label_icon_state {
  fill: #fff;
  font-family: Arial;
  font-size: 12px;
  line-height: 12px;
  font-weight: bold;
}

.map .state .label_state {
  display: none;
  font-family: Arial;
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
}

.map .state:hover .label_state,
.map .state.hover .label_state {
  display: block;
}

.model_green {
  fill: #ffffff;
}

.map .model_green .state .shape {
  fill: #6cb361;
}

.map .model_green .state .shape_great {
  fill: #18641e;
}

.map .model_green .state .shape_attention {
  fill: #FBE433;
}

.map .model_green .state .shape_danger {
  fill: #c31900;
}

.map .model_green .state .shape_very_low {
  fill: #A8A9AD;
}

.map .model_green .state .shape_low {
  fill: #D6EACE;
}

.map .model_green .state .shape_ordinary {
  fill: #93CC2F;
}

.map .model_green .state .shape_good {
  fill: #09AA36;
}

.map .model_green .state .shape_excellent {
  fill: #18641e;
}

.map .model_green .state .icon_state {
  fill: #10592f;
}

.map .model_green .state .icon_state_great {
  fill: #307c0e;
}

.map .model_green .state .icon_state_attention {
  fill: #ac9f1d;
}

.map .model_green .state .icon_state_danger {
  fill: #b81900;
}

.map .model_green .state .icon_state_very_low {
  fill: #A8A9AD;
}

.map .model_green .state .icon_state_low {
  fill: #D6EACE;
}

.map .model_green .state .icon_state_ordinary {
  fill: #93CC2F;
}

.map .model_green .state .icon_state_good {
  fill: #09AA36;
}

.map .model_green .state .icon_state_excellent {
  fill: #055012;
}

.map .model_green .state .label_icon_state {
  fill: black;

}

.map .model_green .state .label_state {
  fill: #666;
}

.map .model_green .state:hover .shape,
.map .model_green .state.hover .shape {
  fill: #12a505;
}

.map .model_green .state:hover .shape_great,
.map .model_green .state.hover .shape_great {
  fill: #198227;
}

.map .model_green .state:hover .shape_attention,
.map .model_green .state.hover .shape_attention {
  fill: #d8cf2c;
}

.map .model_green .state:hover .shape_danger,
.map .model_green .state.hover .shape_danger {
  fill: #da2300;
}

.map .model_green .state:hover .shape_very_low,
.map .model_green .state.hover .shape_very_low {
  fill: #8E8F8F;
}

.map .model_green .state:hover .shape_low,
.map .model_green .state.hover .shape_low {
  fill: #D6E5D1;
}

.map .model_green .state:hover .shape_ordinary,
.map .model_green .state.hover .shape_ordinary {
  fill: #93BA38;
}

.map .model_green .state:hover .shape_good,
.map .model_green .state.hover .shape_good {
  fill: #099C36;
}

.map .model_green .state:hover .shape_excellent,
.map .model_green .state.hover .shape_excellent {
  fill: #18641e;
}

.map .model_green .state:hover .icon_state,
.map .model_green .state.hover .icon_state {
  fill: #2d9835;
}

.map .model_green .state:hover .icon_state_great,
.map .model_green .state.hover .icon_state_great {
  fill: #208f53;
}

.map .model_green .state:hover .icon_state_attention,
.map .model_green .state.hover .icon_state_attention {
  fill: #c5b81f;
}

.map .model_green .state:hover .icon_state_danger,
.map .model_green .state.hover .icon_state_danger {
  fill: #db1a00;
}

.map .model_orange .state .shape {
  fill: #fd7132;
}

.map .model_orange .state .icon_state {
  fill: #6cb361;
}

.map .model_orange .state .label_icon_state {
  fill: #fff;
}

.map .model_orange .state .label_state {
  fill: #666;
}

.map .model_orange .state:hover .shape,
.map .model_orange .state.hover .shape {
  fill: #c93f04;
}

.map .model_orange .state:hover .icon_state,
.map .model_orange .state.hover .icon_state {
  fill: #10592f;
}

.map .model-darkgreen .state .shape {
  fill: #366823;
}

.map .model-darkgreen .state .icon_state {
  fill: #2779c6;
}

.map .model-darkgreen .state .label_icon_state {
  fill: #fff;
}

.map .model-darkgreen .state .label_state {
  fill: #666;
}

.map .model-darkgreen .state:hover .shape,
.map .model-darkgreen .state.hover .shape {
  fill: #4a8c31;
}

.map .model-darkgreen .state:hover .icon_state,
.map .model-darkgreen .state.hover .icon_state {
  fill: #2d9835;
}

.expansionPanel {
  margin-bottom: '5px';
}

.expansionPanelSummary {
  height: 4.5px; 
  background-color:#28a745; 
  color: white; 
  margin: 0px 0px; 
  padding: 0px 0px;
  font-size: 0.9em;
  min-height: 56;
}

.expansionPanelSummary:expanded {
  min-height: 56;
}


.redDot {
  height: 25px;
  width: 25px;
  background-color: #e74c3c;
  border-radius: 50%;
  border-style: solid;
  border-width: thin;
  display: inline-block;
}

.yellowDot {
  height: 25px;
  width: 25px;
  background-color: #f1c40f;
  border-radius: 50%;
  border-style: solid;
  border-width: thin;
  display: inline-block;
}

.greenDot {
  height: 25px;
  width: 25px;
  background-color: #2ecc71;
  border-radius: 50%;
  border-style: solid;
  border-width: thin;
  display: inline-block;
}



.omsTable tr:nth-child(even) {
  background: #CCC
}
.omsTable tr:nth-child(odd) {
  background: #103a21
}

.app_container {
  padding: 15px;
}

.button {

  background-color: #103a21;
}

.reportSection {
  margin-top: 20px;
}

.reportSectionHeader {
  width: 100%;
  height: 2em;
  background-color: #13aa4c;
  color: white;
  font-weight: bold;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 10px;
  font-size: 1.2em;

}


.omsTable {
  width: 100%;
  text-align: center;
  color: black;
  border-style: solid;
  border-color: black;
  margin-bottom: 60px;
  display: inline-table;

}
.omsTable th {
  color: white;
  background-color: #103a21;
  font-size: 1.1em;
  border-style: solid;
  border-color: black;
  border-width: 1px 1px 1px 1px;
  padding: 10px;
  border-style: solid;
  border-color: black;
  border-width: 1px 1px 1px 1px;

}


.omsTable td {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 0.8em;
  border-style: solid;
  border-color: black;
  border-width: 1px 1px 1px 1px;

}

.omsTable tr:nth-child(even) {
  font-weight: bold;
  background-color: #C0D9AF;
}

.omsTable tr:nth-child(odd) {
  font-weight: bold;
  background-color: #FFFFFF;
}

:root {
  --button-font-family: var(--title-font-family);
  --button-font-weight: var(--title-font-weight);
  --button-font-style: var(--title-font-style);

  --button-font-size-extra-small: 12px;
  --button-font-size-small: 14px;
  --button-font-size-default: 14px;
  --button-font-size-large: 14px;
  --button-font-size-very-large: 2em;

  --button-padding-extra-small: 8px;
  --button-padding-small: 16px;
  --button-padding-default: 24px;
  --button-padding-large: 32px;
  --button-padding-very-large: 1em;

  --button-width-very-large: 8em;

  --button-height-extra-small: 26px;
  --button-height-small: 26px;
  --button-height-default: 28px;
  --button-height-large: 28px;
  --button-height-very-large: 3em;

  --button-focus-box-shadow: inset 0 0 3px 3px;
  --button-disabled-background-color: var(--color-light-silver-50);
  --button-disabled-color: var(--color-light-chromium-100);
  --button-outline-disabled-border-color: var(--color-light-silver-50);
  --button-outline-disabled-color: var(--color-light-silver-100);
  --button-block-background: var(--color-light-grey-20);
  --button-block-color: var(--color-light-coal-100);
  --button-color: var(--color-white);
  --button-dark-color: var(--color-light-coal-100);

  --button-focus-animation-duration: 0.3s;
  --button-focus-animation-start: inset -200px 0 3px 0;
  --button-focus-animation-end: inset 0 0 3px 0;

  --button-success-color: var(--color-light-greenish-100);
  --button-success-gradient: var(--color-light-greenish-gradient);
  --button-success-focus: var(--color-light-greenish-50);
  --button-success-hover: var(--color-light-greenish-120);
  --button-success-active: var(--color-light-greenish-150);

  --button-neutral-color: var(--color-light-grey-50);
  --button-neutral-focus: var(--color-light-iron-50);
  --button-neutral-hover: var(--color-light-iron-100);
  --button-neutral-active: var(--color-light-grey-100);

  --button-danger-color: var(--color-light-salmon-100);
  --button-danger-gradient: var(--color-light-salmon-gradient);
  --button-danger-focus: var(--color-light-salmon-50);
  --button-danger-hover: var(--color-light-salmon-120);
  --button-danger-active: var(--color-light-salmon-150);
}

/*
 * Green Button
 * */

@-webkit-keyframes light-green-focus {

  0% {
    box-shadow: var(--button-focus-animation-start) var(--button-success-color);
  }

  100% {
    box-shadow: var(--button-focus-animation-end) var(--button-success-color);
  }
}

@keyframes light-green-focus {

  0% {
    box-shadow: var(--button-focus-animation-start) var(--button-success-color);
  }

  100% {
    box-shadow: var(--button-focus-animation-end) var(--button-success-color);
  }
}

.light-green {
  color: var(--button-success-color);
  background-color: var(--button-success-color);
  background-image: var(--button-success-gradient);
  border-color: var(--button-success-color);
  will-change: box-shadow;
}

.light-green:focus {
  -webkit-animation-name: light-green-focus;
          animation-name: light-green-focus;
  -webkit-animation-duration: var(--button-focus-animation-duration);
          animation-duration: var(--button-focus-animation-duration);
  background-color: var(--button-success-focus);
  background-image: none;
}

.light-green:hover {
  -webkit-animation: none;
          animation: none;
  background-color: var(--button-success-hover);
  background-image: none;
  border-color: var(--button-success-hover);
}

.light-green:active {
  -webkit-animation: none;
          animation: none;
  background-color: var(--button-success-active);
  background-image: none;
  border-color: var(--button-success-active);
}

@-webkit-keyframes dark-green-focus {

  0% {
    box-shadow: var(--button-focus-animation-start) var(--button-success-color);
  }

  100% {
    box-shadow: var(--button-focus-animation-end) var(--button-success-color);
  }
}

@keyframes dark-green-focus {

  0% {
    box-shadow: var(--button-focus-animation-start) var(--button-success-color);
  }

  100% {
    box-shadow: var(--button-focus-animation-end) var(--button-success-color);
  }
}

.dark-green {
  color: var(--button-success-color);
  background-color: #296340;
  background-image: var(--button-success-gradient);
  border-color: var(--button-success-color);
  will-change: box-shadow;
}

.dark-green:focus {
  -webkit-animation-name: dark-green-focus;
          animation-name: dark-green-focus;
  -webkit-animation-duration: var(--button-focus-animation-duration);
          animation-duration: var(--button-focus-animation-duration);
  background-color: var(--button-success-focus);
  background-image: none;
}

.dark-green:hover {
  -webkit-animation: none;
          animation: none;
  background-color: var(--button-success-hover);
  background-image: none;
  border-color: var(--button-success-hover);
}

.dark-green:active {
  -webkit-animation: none;
          animation: none;
  background-color: var(--button-success-active);
  background-image: none;
  border-color: var(--button-success-active);
}


/*
 * Silver Button
 * */

@-webkit-keyframes light-silver-focus {

  0% {
    box-shadow: var(--button-focus-animation-start) var(--button-neutral-color);
  }

  100% {
    box-shadow: var(--button-focus-animation-end) var(--button-neutral-color);
  }
}

@keyframes light-silver-focus {

  0% {
    box-shadow: var(--button-focus-animation-start) var(--button-neutral-color);
  }

  100% {
    box-shadow: var(--button-focus-animation-end) var(--button-neutral-color);
  }
}

.light-silver {
  color: var(--button-neutral-color);
  background-color: var(--button-neutral-color);
  border-color: var(--button-neutral-color);
  will-change: box-shadow;
}

.light-silver:focus {
  -webkit-animation-name: light-silver-focus;
          animation-name: light-silver-focus;
  -webkit-animation-duration: var(--button-focus-animation-duration);
          animation-duration: var(--button-focus-animation-duration);
  background-color: var(--button-neutral-focus);
  background-image: none;
}

.light-silver:hover {
  -webkit-animation: none;
          animation: none;
  background-color: var(--button-neutral-hover);
  border-color: var(--button-neutral-hover);
}

.light-silver:active {
  -webkit-animation: none;
          animation: none;
  background-color: var(--button-neutral-active);
  background-image: none;
  border-color: var(--button-neutral-active);
}

/*
 * Red Button
 * */

@-webkit-keyframes light-red-focus {

  0% {
    box-shadow: var(--button-focus-animation-start) var(--button-danger-color);
  }

  100% {
    box-shadow: var(--button-focus-animation-end) var(--button-danger-color);
  }
}

@keyframes light-red-focus {

  0% {
    box-shadow: var(--button-focus-animation-start) var(--button-danger-color);
  }

  100% {
    box-shadow: var(--button-focus-animation-end) var(--button-danger-color);
  }
}

.light-red {
  color: var(--button-danger-color);
  background-color: var(--button-danger-color);
  background-image: var(--button-danger-gradient);
  border-color: var(--button-danger-color);
  will-change: box-shadow;
}

.light-red:focus {
  -webkit-animation-name: light-red-focus;
          animation-name: light-red-focus;
  -webkit-animation-duration: var(--button-focus-animation-duration);
          animation-duration: var(--button-focus-animation-duration);
  background-color: var(--button-danger-focus);
  background-image: none;
}

.light-red:hover {
  -webkit-animation: none;
          animation: none;
  background-color: var(--button-danger-hover);
  background-image: none;
  border-color: var(--button-danger-hover);
}

.light-red:active {
  -webkit-animation: none;
          animation: none;
  background-color: var(--button-danger-active);
  background-image: none;
  border-color: var(--button-danger-active);
}

.button {
  border: none;
  border-radius: 3px;
  font-family: var(--button-font-family);
  font-weight: var(--button-font-weight);
  font-style: var(--button-font-style);
  letter-spacing: var(--letter-spacing);
  text-transform: uppercase;
  cursor: pointer;
}

.button:focus {
  outline: none;
}

/*
 * disabled button style
 * */

.button:disabled:hover,
.button:disabled {
  cursor: not-allowed;
  background: var(--button-disabled-background-color);
  color: var(--button-disabled-color);
}

.button.outline:disabled,
.button.clean:disabled {
  background: transparent;
  color: var(--button-outline-disabled-color);
}

/*
 * Reset rules defined by button color
 * */

.flat {
  background-image: none;
}

.flat,
.flat:hover,
.flat:active,
.flat:focus,
.gradient,
.gradient:hover,
.gradient:active,
.gradient:focus,
.outline:hover,
.outline:active,
.outline:focus,
.clean:hover,
.clean:active,
.clean:focus {
  color: var(--button-color);
}

/*
 * Outline borders
 * */

.outline {
  border-style: solid;
  border-width: 1px;
}

.outline:disabled {
  border: 1px solid var(--button-outline-disabled-border-color);
}

/*
 * Transparent Backgrounds
 * */

.outline,
.clean {
  background-color: transparent;
  background-image: none;
}

/*
 * Icon Alignment
 * */

.button svg {
  margin: 0 8px 0 0;
}

/*
 * Button sizes
 * */

.extra-small {
  padding: 0 var(--button-padding-extra-small);
  font-size: var(--button-font-size-extra-small);
  height: var(--button-height-extra-small);
}

.small {
  padding: 0 var(--button-padding-small);
  font-size: var(--button-font-size-small);
  height: var(--button-height-small);
}

.default {
  padding: 0 var(--button-padding-default);
  font-size: var(--button-font-size-default);
  height: var(--button-height-default);
}

.large {
  padding: 0 var(--button-padding-large);
  font-size: var(--button-font-size-large);
  height: var(--button-height-large);
}

.very-large {
  padding: 0 var(--button-padding-very-large);
  font-size: var(--button-font-size-very-large);
  height: var(--button-height-very-large);
  width: var(--button-width-very-large);
}

/*
 * Remove 1px related to the border-width
 * */

.outline.extra-small {
  padding: 0 calc(var(--button-padding-extra-small) - 1px);
}

.outline.small {
  padding: 0 calc(var(--button-padding-small) - 1px);
}

.outline.default {
  padding: 0 calc(var(--button-padding-default) - 1px);
}

.outline.large {
  padding: 0 calc(var(--button-padding-large) - 1px);
}
.outline.very-large {
  padding: 0 calc(var(--button-padding-very-large) - 1px);
}



.omsTable tr:nth-child(even) {
  background: #CCC
}
.omsTable tr:nth-child(odd) {
  background: #103a21
}

.app_container {
  padding: 15px;
}

.button {

  background-color: #103a21;
}

.reportSection {
  margin-top: 20px;
}

.reportSectionHeader {
  width: 100%;
  height: 2em;
  background-color: #13aa4c;
  color: white;
  font-weight: bold;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 10px;
  font-size: 1.2em;

}


.omsTable {
  width: 100%;
  text-align: center;
  color: black;
  border-style: solid;
  border-color: black;
  margin-bottom: 60px;
  display: inline-table;

}
.omsTable th {
  color: white;
  background-color: #103a21;
  font-size: 1.1em;
  border-style: solid;
  border-color: black;
  border-width: 1px 1px 1px 1px;
  padding: 10px;
  border-style: solid;
  border-color: black;
  border-width: 1px 1px 1px 1px;

}


.omsTable td {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 0.8em;
  border-style: solid;
  border-color: black;
  border-width: 1px 1px 1px 1px;

}

.omsTable tr:nth-child(even) {
  font-weight: bold;
  background-color: #C0D9AF;
}

.omsTable tr:nth-child(odd) {
  font-weight: bold;
  background-color: #FFFFFF;
}





.redDot {
  height: 25px;
  width: 25px;
  background-color: #e74c3c;
  border-radius: 50%;
  border-style: solid;
  border-width: thin;
  display: inline-block;
}

.yellowDot {
  height: 25px;
  width: 25px;
  background-color: #f1c40f;
  border-radius: 50%;
  border-style: solid;
  border-width: thin;
  display: inline-block;
}

.greenDot {
  height: 25px;
  width: 25px;
  background-color: #2ecc71;
  border-radius: 50%;
  border-style: solid;
  border-width: thin;
  display: inline-block;
}



.app_container {
  padding: 15px;
}

.TableHub {
  border-collapse: collapse;
  width: 100%
}

.TableHub td th {
  border: 3px solid #ddd;
  padding: 8px;
}

.TableHub td {
  border: 3px solid #ddd;
  color: black;
  text-align: center;
}

.TableHub th {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  background-color: #4CAF50;
  color: white;
  border: 3px solid #ddd;
}

.TableHub tr:nth-child(even){
  background-color: #f2f2f2;
}

.TableHub tr:hover {
  background-color: #ddd;
}

/* stylelint-disable */

.ReactTable {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.ReactTable * {
  box-sizing: border-box
}

.ReactTable .rt-table {
  -webkit-flex: auto 1;
          flex: auto 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
          align-items: stretch;
  width: 100%;
  border-collapse: collapse;
  overflow: auto
}

.ReactTable .rt-thead {
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ReactTable .rt-thead.-headerGroups {
  background: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05)
}

.ReactTable .rt-thead.-filters {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.ReactTable .rt-thead.-filters input, .ReactTable .rt-thead.-filters select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline: none
}

.ReactTable .rt-thead.-filters .rt-th {
  border-right: 1px solid rgba(0, 0, 0, 0.02)
}

.ReactTable .rt-thead.-header {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15)
}

.ReactTable .rt-thead .rt-tr {
  text-align: center
}

.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
}

.ReactTable .rt-thead .rt-th.-cursor-pointer, .ReactTable .rt-thead .rt-td.-cursor-pointer {
  cursor: pointer
}

.ReactTable .rt-thead .rt-th:last-child, .ReactTable .rt-thead .rt-td:last-child {
  border-right: 0
}

.ReactTable .rt-thead .rt-resizable-header {
  overflow: visible;
}

.ReactTable .rt-thead .rt-resizable-header:last-child {
  overflow: hidden
}

.ReactTable .rt-thead .rt-resizable-header-content {
  overflow: hidden;
  text-overflow: ellipsis
}

.ReactTable .rt-thead .rt-header-pivot {
  border-right-color: #f7f7f7
}

.ReactTable .rt-thead .rt-header-pivot:after, .ReactTable .rt-thead .rt-header-pivot:before {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none
}

.ReactTable .rt-thead .rt-header-pivot:after {
  border-color: rgba(255, 255, 255, 0);
  border-left-color: #fff;
  border-width: 8px;
  margin-top: -8px
}

.ReactTable .rt-thead .rt-header-pivot:before {
  border-color: rgba(102, 102, 102, 0);
  border-left-color: #f7f7f7;
  border-width: 10px;
  margin-top: -10px
}

.ReactTable .rt-tbody {
  -webkit-flex: 99999 1 auto;
          flex: 99999 1 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  overflow: auto;
}

.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
}

.ReactTable .rt-tbody .rt-tr-group:last-child {
  border-bottom: 0
}

.ReactTable .rt-tbody .rt-td {
  border-right: 1px solid rgba(0, 0, 0, 0.02);
}

.ReactTable .rt-tbody .rt-td:last-child {
  border-right: 0
}

.ReactTable .rt-tbody .rt-expandable {
  cursor: pointer
}

.ReactTable .rt-tr-group {
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
          align-items: stretch
}

.ReactTable .rt-tr {
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
  display: -webkit-inline-flex;
  display: inline-flex
}

.ReactTable .rt-th, .ReactTable .rt-td {
  -webkit-flex: 1 0;
          flex: 1 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 7px 5px;
  overflow: hidden;
  transition: .3s ease;
  transition-property: width, min-width, padding, opacity;
}

.ReactTable .rt-th.-hidden, .ReactTable .rt-td.-hidden {
  width: 0 !important;
  min-width: 0 !important;
  padding: 0 !important;
  border: 0 !important;
  opacity: 0 !important
}

.ReactTable .rt-expander {
  display: inline-block;
  position: relative;
  margin: 0;
  color: transparent;
  margin: 0 10px;
}

.ReactTable .rt-expander:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-90deg);
  transform: translate(-50%, -50%) rotate(-90deg);
  border-left: 5.04px solid transparent;
  border-right: 5.04px solid transparent;
  border-top: 7px solid rgba(0, 0, 0, 0.8);
  transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
  cursor: pointer
}

.ReactTable .rt-expander.-open:after {
  -webkit-transform: translate(-50%, -50%) rotate(0);
  transform: translate(-50%, -50%) rotate(0)
}

.ReactTable .rt-resizer {
  display: inline-block;
  position: absolute;
  width: 36px;
  top: 0;
  bottom: 0;
  right: -18px;
  cursor: col-resize;
  z-index: 10
}

.ReactTable .rt-tfoot {
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
}

.ReactTable .rt-tfoot .rt-td {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.ReactTable .rt-tfoot .rt-td:last-child {
  border-right: 0
}

.ReactTable.-striped .rt-tr.-odd {
  background: rgba(0, 0, 0, 0.03)
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: rgba(0, 0, 0, 0.05)
}

.ReactTable .-pagination {
  z-index: 1;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: stretch;
          align-items: stretch;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 3px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}

.ReactTable .-pagination input, .ReactTable .-pagination select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline: none
}

.ReactTable .-pagination .-btn {
  -webkit-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 3px;
  padding: 6px;
  font-size: 1em;
  color: rgba(0, 0, 0, 0.6);
  background: rgba(0, 0, 0, 0.1);
  transition: all .1s ease;
  cursor: pointer;
  outline: none;
}

.ReactTable .-pagination .-btn[disabled] {
  opacity: .5;
  cursor: default
}

.ReactTable .-pagination .-btn:not([disabled]):hover {
  background: rgba(0, 0, 0, 0.3);
  color: #fff
}

.ReactTable .-pagination .-previous, .ReactTable .-pagination .-next {
  -webkit-flex: 1 1;
          flex: 1 1;
  text-align: center
}

.ReactTable .-pagination .-center {
  -webkit-flex: 1.5 1;
          flex: 1.5 1;
  text-align: center;
  margin-bottom: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-around;
          justify-content: space-around
}

.ReactTable .-pagination .-pageInfo {
  display: inline-block;
  margin: 3px 10px;
  white-space: nowrap
}

.ReactTable .-pagination .-pageJump {
  display: inline-block;
}

.ReactTable .-pagination .-pageJump input {
  width: 70px;
  text-align: center
}

.ReactTable .-pagination .-pageSizeOptions {
  margin: 3px 10px
}

.ReactTable .rt-noData {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.8);
  transition: all .3s ease;
  z-index: 1;
  pointer-events: none;
  padding: 20px;
  color: rgba(0, 0, 0, 0.5)
}

.ReactTable .-loading {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  transition: all .3s ease;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}

.ReactTable .-loading > div {
  position: absolute;
  display: block;
  text-align: center;
  width: 100%;
  top: 50%;
  left: 0;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
  -webkit-transform: translateY(-52%);
  transform: translateY(-52%);
  transition: all .3s cubic-bezier(.25, .46, .45, .94)
}

.ReactTable .-loading.-active {
  opacity: 1;
  z-index: 2;
  pointer-events: all;
}

.ReactTable .-loading.-active > div {
  -webkit-transform: translateY(50%);
  transform: translateY(50%)
}

.ReactTable .rt-resizing .rt-th, .ReactTable .rt-resizing .rt-td {
  transition: none !important;
  cursor: col-resize;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.table {
  width: 45%;
  display: inline-block;
  padding: 50px 30px 0 30px;
  color: #1f1f1f !important;
}

.table-full {
  display: block;
  padding: 30px;
  color: #1f1f1f !important;
}

.rt-tr-group {
  width: 100% !important;
  text-align: center;
  font-size: 1em;
  font-weight: bold;
  padding: 3px;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.rt-tr-group:hover {
  background: rgba(0,0,0,0.05);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
}

.rt-tr-group:nth-child(odd) {
  width: 100% !important;
  text-align: center;
  font-size: 1em;
  font-weight: bold;
  padding: 3px;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  background: rgba(0,0,0,0.03);
}

.rt-th {
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.src-pages-NocMonitoring--style-rt-th.-sort-asc,
.src-pages-NocMonitoring--style-rt-th.-sort-desc {
  background-color: #103a21;
}

.rt-td {
  text-align: center;
  font-weight: normal;
}

.rt-tbody {
  overflow: hidden !important;
}

.sort__asc {
  box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 5px;
  position: absolute;
  margin-left: -5px;
  top: 0;
}

.sort__desc {
  box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 5px;
  position: absolute;
  margin-left: -5px;
}

.-loading {
  display: none;
}

.-pagination {
  display: -webkit-inline-flex;
  display: inline-flex;
  text-align: center;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  padding-top: 5px;
}

.-pagination button {
  cursor: pointer;
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 3px;
  padding: 6px;
  font-size: 1em;
  color: #ffffff;
  background: #103a21;
  transition: all .3s ease;
  outline: none;
}

.-pagination button:hover {
  background-color: rgba(9, 170, 54);
}

.-pagination input {
  opacity: .5;
  text-align: left;
  display: block;
  width: 100%;
  height: 20px;
  border: 0;
  border-radius: 3px;
  padding: 6px;
  font-size: 1em;
  color: #1f1f1f;
  background: rgba(0,0,0,0.1);
  transition: all .1s ease;
  margin: 3px;
}

.-pagination select {
  display: none;
}

.table__footer {
  padding: 10px;
  text-align: center;
}




.app_container {
  padding: 15px;
}

.cadenceTable {
  width: 100%;
  text-align: center;
  font-size: 1.5em;
  color: black;
  margin-bottom: 80px;
}

.cadenceTable th {
  color: white;
  background-color: #103a21;
  border-style: solid;
  border-color: #FFFFFF;
  padding: 10px;
}

table, th, td {
    border-collapse: collapse;
}

td {
  padding-top: 10px;
  padding-bottom: 10px;
}

.cadenceTable td:first-child {
  font-weight: bold;
}

.cadenceTable tr:nth-child(3) {
  background-color: #b7c0ce;
  font-weight: bold;
}





.redDot {
  height: 25px;
  width: 25px;
  background-color: #e74c3c;
  border-radius: 50%;
  border-style: solid;
  border-width: thin;
  display: inline-block;
}

.yellowDot {
  height: 25px;
  width: 25px;
  background-color: #f1c40f;
  border-radius: 50%;
  border-style: solid;
  border-width: thin;
  display: inline-block;
}

.greenDot {
  height: 25px;
  width: 25px;
  background-color: #2ecc71;
  border-radius: 50%;
  border-style: solid;
  border-width: thin;
  display: inline-block;
}

.grayDot {
  height: 25px;
  width: 25px;
  background-color: gray;
  border-radius: 50%;
  border-style: solid;
  border-width: thin;
  display: inline-block;
}



.app_container {
  padding: 15px;
}

.TableHub {
  border-collapse: collapse;
  width: 100%
}

.TableHub td th {
  border: 3px solid #ddd;
  padding: 8px;
}

.TableHub td {
  border: 3px solid #ddd;
  color: black;
  text-align: center;
}

.TableHub th {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  background-color: #4CAF50;
  color: white;
  border: 3px solid #ddd;
}

.TableHub tr:nth-child(even){
  background-color: #f2f2f2;
}

.TableHub tr:hover {
  background-color: #ddd;
}

.buttonLinks {
  /* font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important; */
  font-size: 11px;
  color: #00cc00;
  padding: 0px 0px;
  background-color: #ffffff;
  border-radius: 10px;
  border: 0px solid #00cc00;
}

.customizedTable {
  top: auto;
  /* width: 70% !important; */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1.5px solid #dbdbdb;
  border-radius: 4px;
  opacity: 1;
}

.subtitleTable {
  font-weight: bolder !important;
}

/* .MuiTableCell-body {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
} */

.ant-pagination-item:hover {
  border-color: #14aa4b !important;
  color: black !important;
}

.pageTitle {
  color: #00000099;
  font-weight: bolder;
  margin-left: 3%;
  /* font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif; */
}

@media (min-width: 1200px) {
  .left {
    margin-left: 1%;
  }

  .right {
    margin-left: 0.5%;
    /* margin-right: 1%; */
  }
}
@media (max-width: 1199px) {
  .left {
    margin-left: 3%;
  }

  .right {
    margin-left: 3%;
  }
}
@media (max-width: 1050px) {
  .left {
    margin-left: 3%;
  }

  .right {
    margin-left: 3%;
  }
}

@media (max-width: 991px) {
  .left {
    margin-right: 5%;
  }

  .right {
    margin-right: 5%;
  }
}

body {
  background: #f0f2f5 !important;
}

.serchClientLabel {
  color: #00000061;
  text-align: left;
}

.titleClient {
  text-align: left !important;
  font-size: large !important;
  font-weight: bold !important;
}

.ant-pagination-item-active {
  background: #14aa4b !important;
  color: white !important;
  border-color: white !important;
}

.titleIconClient {
  padding-right: inherit;
}

.iconClient {
  padding-right: inherit;
}

.iconClientComputer {
  padding-right: inherit;
  width: 38px;
  /* width: 60px; */
}

.titleIconXray {
  padding-right: inherit;
  width: 48px;
}

.app_container {
  padding: 15px;
  text-align: center;
}

.material-icons.green {
  color: #37b766;
  margin-right: auto;
  font-size: 24px;
}

.material-icons.yellow {
  color: #f3ff54;
  margin-right: auto;
}

.material-icons.red {
  color: #f72525;
  margin-right: auto;
}

.customModal {
  /* background-color: #191919; */
  color: #00c853;
  font-size: 1em;
  width: 500em;
  /* widows: 200em; */
  -webkit-align-items: stretch;
          align-items: stretch;
}

.modalTitle {
  color: #00c853;
  font-size: 1.3em;
  font-weight: bold;
}

.modalCloseButton {
  font-size: 0.8em;
  background-color: #fff;
  font-weight: lighter;
  border: #191919;
  color: #00c853;
  text-align: center;
}

.cadenceTable {
  width: 100%;

  text-align: center;

  font-size: 30px;

  color: black;
}

.cadenceTable th {
  color: white;
  background-color: #2c3e50;

  padding: 10px;
}

table,
th,
td {
  border-collapse: collapse;
}

td {
  padding-top: 10px;
  padding-bottom: 10px;
}

.cadenceTable td:first-child {
  font-weight: bold;
}

.ant-menu-horizontal > .ant-menu-item:hover {
  color: #14aa4b !important;
  border-bottom: 2px solid #14aa4b !important;
}

.ant-menu-horizontal > .ant-menu-item-selected {
  color: #14aa4b !important;
  border-bottom: 2px solid #14aa4b !important;
}

.ant-menu-horizontal > .ant-menu-item-selected > a {
  color: #14aa4b !important;
}

.ant-menu-horizontal > .ant-menu-item > a:hover {
  color: #14aa4b !important;
}

:root {
  /* WallBoard Color */
  --color-light-blue-100: #103a21;
  --color-dark-background: #0d131a;
  --color-dark-grey-light-font: #8295a6;
  --color-dark-card-header-background: #262c32;
  --color-dark-card-light-background: #414b55;
  --color-dark-card-default-background: #161d26;
  --color-light-green-wallboard: #94c83d;
  --color-green-wallboard: #13aa4c;
  --color-yellow-wallboard: #f1aa00;
  --color-red-wallboard: #e64b28;
  --color-alice-blue: #f0f8ff;
  /* Pagarme Colors */
  --color-light-orange-100: #ffa317;
  --color-light-orange-50: #ffd18b;
  --color-light-orange-20: #ffedd1;
  --color-light-red-100: #c2143e;
  --color-light-red-50: #e0899e;
  --color-light-red-20: #f3d0d8;
  --color-light-purple-50: #ca99e4;
  --color-light-purple-20: #ead6f4;
  --color-light-blue-50: #9cdee8;
  --color-light-blue-20: #d8f2f6;
  --color-light-green-100: #38be6a;
  --color-light-green-50: #9bdeb4;
  --color-light-green-20: #d7f2e1;
  --color-light-salmon-100: #ff796f;
  --color-light-salmon-120: #dc6860;
  --color-light-salmon-150: #ba5851;
  --color-light-greenish-120: #32b88b;
  --color-light-greenish-100: #37cc9a;
  --color-light-silver-40: #eeeeee;
  --color-light-silver-20: #f6f6f6;
  --color-light-coal-100: #454545;
  --color-light-steel-100: #757575;
  --color-light-chromium-100: #a0a0a0;
  --color-light-chromium-50: #cfcfcf;
  --color-light-steel-50: #bababa;
  --color-light-coal-50: #a2a2a2;
  --color-white: #ffffff;
  --color-light-silver-50: #e9e9e9;
  --color-light-silver-100: #d4d4d4;
  --color-light-platinum-100: #c2cbd2;
  --color-light-platinum-50: #e0e5e8;
  --color-light-platinum-20: #f3f5f6;
  --color-light-iron-100: #a4acb2;
  --color-light-iron-50: #d1d5d8;
  --color-light-iron-20: #edeef0;
  --color-light-grey-100: #858c8f;
  --color-light-grey-50: #c2c5c7;
  --color-light-grey-20: #e7e8e9;
  --color-light-smoke-100: #e0e0e0;
  --color-light-smoke-50: #efefef;
  --color-black: #000000;
  --color-light-smoke-40: #f3f3f3;
  --color-light-greenish-150: #2b9e77;
  --color-light-greenish-20: #c3f0e0;
  --color-light-salmon-20: #ffc9c5;
  --color-light-salmon-50: #ffa19a;
  --color-light-greenish-50: #73dbb8;
  --color-light-purple-100: #8459b9;
  --color-light-steel-200: #5c5c5c;
  --color-light-carbon-100: #1f1f1f;
  --color-light-carbon-50: #2c2c2c;
  --color-light-carbon-40: #383838;

  --color-light-warning: var(--color-light-purple-100);
  --color-light-error: var(--color-light-red-100);
  --color-light-info: var(--color-light-orange-100);
  --color-light-success: var(--color-light-blue-100);

  --color-light-greenish-gradient:
    linear-gradient(
      var(--gradient-angle),
      var(--color-light-greenish-100) 10%,
      #45babf 90%
    );
  --color-light-salmon-gradient:
    linear-gradient(
      var(--gradient-angle),
      #fc8a5d 10%,
      var(--color-light-salmon-100) 90%
    );
}

:root {
  --gradient-angle: 90deg;
}

@font-face {
  font-family: "Assistant";
  src:
    url(/static/media/assistant-bold.2d33fe63.woff2) format("woff2"),
    url(/static/media/assistant-bold.7347435e.woff) format("woff");
  font-weight: bolder;
  font-style: normal;
}

@font-face {
  font-family: "Assistant";
  src:
    url(/static/media/assistant-regular.275d9bec.woff2) format("woff2"),
    url(/static/media/assistant-regular.ecfab249.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}


:root {
  --spacing-large: 32px;
  --spacing-medium: 24px;
  --spacing-small: 16px;
}

:root {
  --headline-font-size: 30px;
  --display-1-font-size: 36px;
  --display-2-font-size: 48px;
  --display-3-font-size: 60px;
  --display-4-font-size: 72px;
  --caption-font-size: 12px;
  --body-font-size: 16px;
  --blockquote-border-radius: 2px;
  --blockquote-border: 5px solid var(--color-light-greenish-100);

  --title-font-family: "Assistant";
  --title-color: var(--color-light-coal-100);
  --title-font-weight: bold;
  --title-font-style: normal;
  --body-font-family: "Assistant";
  --body-link-color: var(--color-light-greenish-100);
  --letter-spacing: 0.02em;
  --body-color: var(--color-light-chromium-100);
}

/* stylelint-disable */
body {
  font-family: "Assistant";
  font-family: var(--body-font-family);
  font-size: 16px;
  font-size: var(--body-font-size);
  color: var(--color-light-chromium-100);
  color: var(--body-color);
}

body * {
  letter-spacing: 0.02em;
  letter-spacing: var(--letter-spacing);
}

h1,
h2,
h3,
h4,
h5,
h6,
.display,
.headline {
  font-family: "Assistant";
  font-family: var(--title-font-family);
  font-weight: bold;
  font-weight: var(--title-font-weight);
  font-style: normal;
  font-style: var(--title-font-style);
  color: var(--color-light-coal-100);
  color: var(--title-color);
}

h1 {
  font-size: 24px;
  margin: var(--spacing-medium) 0;
}

h2 {
  font-size: 20px;
  margin: var(--spacing-medium) 0;
}

h3 {
  font-size: 18px;
  margin: var(--spacing-small) 0;
}

p {
  margin-bottom: 1em;
}

a {
  font-weight: bold;
  color: var(--color-light-greenish-100);
  color: var(--body-link-color);
  text-decoration: unset;
}

a:hover {
  text-decoration: underline;
}

ul,
ol {
  padding: 0 var(--spacing-medium);
}

li {
  list-style-type: disc;
}

blockquote {
  border-radius: 2px;
  border-radius: var(--blockquote-border-radius);
  border-left: 5px solid var(--color-light-greenish-100);
  border-left: var(--blockquote-border);
  padding-left: var(--spacing-small);
  margin: 0;
}
/* stylelint-enable */

.app_container {
  padding: 15px;
}

.spacingDash{
    opacity: 0;
}

.cadenceTable {
  width: 100%;
  text-align: center;
  font-size: 1.5em;
  color: black;
  margin-bottom: 80px;
}

.cadenceTable th {
  color: white;
  background-color: #103a21;
  padding: 10px;
}



/* .cadenceTable tr:nth-child(7){
  border-top-style: solid;
  border-top-color: #2c3e50;
} */
/*
.cadenceTable tr:nth-child(3){
  background-color: #b7c0ce;
  border-bottom-style: groove;
}

/*
Personaliza a linha de Total
*/
/* .cadenceTable tr:nth-child(3) td:nth-child(1){
  background-color: #ffffff;
}

.cadenceTable tr:nth-child(3) td:nth-child(7){
  background-color: #ffffff;
}

.cadenceTable tr:nth-child(3) td:nth-child(11){
  background-color: #ffffff;
} */ */



.slaOplTable {
  border-collapse: collapse;
  width: 100%;
}

.slaOplTable td th {
  border: 3px solid #ddd;
  padding: 8px;
}

.slaOplTable td {
  padding: 10px 10px 10px 10px;
  border: 3px solid #ddd;
  color: black;
  text-align: center;
}

.slaOplTable th {
  padding: 20px 40px 20px 40px;
  text-align: center;
  background-color: #4CAF50;
  color: white;
  border: 3px solid #ddd;
}


table, th, td {
  border-collapse: collapse;
}

td {
  padding-top: 5px;
  padding-bottom: 5px;
}

.cadenceTableSpacing {
  width: 10px;
}

.cadenceTable td:first-child {
  font-weight: bold;
}

.tdHighlight {
  font-weight: bold;
}

.tdHighlightRed {
  font-weight: bold;
  background-color: #ff5e57;
  color: white;
}

.totalLabel {
  color: black;
}

.stoneLabel {
  color: #44bd32;
}

.elavonLabel {
  color: #2980b9;
}

.stoneLightLabel {
  color: #356E1C;
}

.stoneGrayLabel {
  color: #8A8E89;
}

.stoneDarkBlueLabel {
  color: #2c3e50;
}

.reportSection {
  margin-top: 20px;
}

.reportSectionHeader {
  height: 40px;
  width: 100%;
  background-color: #13aa4c;
  color: white;
  font-weight: bold;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 10px;
  font-size: 1.2em;
}

.machineMachineConsumptionTable {
  border-collapse: collapse;
}

.machineMachineConsumptionTable td th {
  border: 3px solid #ddd;
  padding: 8px;
}

.machineMachineConsumptionTable td {
  padding: 10px 10px 10px 10px;
  border: 3px solid #ddd;
  color: black;
  text-align: center;
}

.machineMachineConsumptionTable th {
  padding: 20px 40px 20px 40px;
  text-align: center;
  background-color: #4CAF50;
  color: white;
  border: 3px solid #ddd;
}

.machineMachineConsumptionTable tr:nth-child(even){
  background-color: #f2f2f2;
}

.machineMachineConsumptionTable tr:hover {
  background-color: #ddd;
}

.selfLogisticsMap {
  border: 2px solid #18641e;
  display: inline-block;
  margin: 0.5em 0.3em 0.5em 0.3em;
  padding: 2px;
  text-align: center;
  font-weight: bold;
  font-size: 13;
  color: #18641e;
  background-color: white;
}

.selfLogisticsMap:hover {
  background-color: #D6EACE;
}

.dailyAverageAgingTableLeft {
  color: #f7fcff;
  background-color: #00b72f;
  padding: 5px;
  border: 3px;
  font-weight: bold;
  border-color: gray;
  -webkit-align-items: center;
          align-items: center;
}

.dailyAverageAgingTableRight {
  color: black;
  border: 3px;
  padding: 0 25px 0 25px;
  font-weight: bold;
  border-color: gray;
  -webkit-align-items: center;
          align-items: center;
}

.loadingMessage {
  font-size: 1.2em;
}

.expansionPanel {
  margin-bottom: '5px';
}

.expansionPanelSummary {
  height: 4.5px; 
  background-color:#28a745; 
  color: white; 
  margin: 0px 0px; 
  padding: 0px 0px;
  font-size: 0.9em;
  min-height: 56;
}

.expansionPanelSummary:expanded {
  min-height: 56;
}





.reportSectionHeader {
  height: 40px;
  width: 100%;
  background-color: #103a21;
  color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 10px;
  font-size: 1.5em;
}


.redDot {
  height: 25px;
  width: 25px;
  background-color: #e74c3c;
  border-radius: 50%;
  border-style: solid;
  border-width: thin;
  display: inline-block;
}

.yellowDot {
  height: 25px;
  width: 25px;
  background-color: #f1c40f;
  border-radius: 50%;
  border-style: solid;
  border-width: thin;
  display: inline-block;
}

.greenDot {
  height: 25px;
  width: 25px;
  background-color: #2ecc71;
  border-radius: 50%;
  border-style: solid;
  border-width: thin;
  display: inline-block;
}

.triageHistoryGraph {
  width: 100%;
  display: inline-block;
  vertical-align: top;
}

.app_container {
  padding: 15px;
}

.cadenceTable {
  width: 100%;
  text-align: center;
  font-size: 1.5em;
  color: black;
  margin-bottom: 80px;
}

.cadenceTable th {
  color: white;
  background-color: #103a21;
  padding: 10px;
}

table, th, td {
    border-collapse: collapse;
}

td {
  padding-top: 10px;
  padding-bottom: 10px;
}

.cadenceTable td:first-child {
  font-weight: bold;
}

.triageStatusTable {
  width: 100%;
  display: inline-block;
  padding-bottom: 20%;
  padding-bottom: 20%;
}

.triageStatusTable td th {
  border: 3px solid #ddd;
  padding: 8px;
}

.triageStatusTable td {
  padding: 10px 10px 10px 10px;
  border: 3px solid #ddd;
  color: black;
  text-align: center;

}

.tdHighlightTotal {
  font-weight: bold;
  font-size: 1.3em;
  color: white;
}

.triageStatusTable th {
  padding: 20px 40px 20px 40px;
  text-align: center;
  background-color: #4CAF50;
  color: white;
  border: 3px solid #ddd;
}

.triageStatusTable tr:nth-child(even){
  background-color: #f2f2f2;
}

.triageStatusTable tr:hover {
  background-color: #ddd;
}

.expansionPanel {
  margin-bottom: '5px';
}

.expansionPanelSummary {
  height: 4.5px;
  background-color:#28a745;
  color: white;
  margin: 0px 0px;
  padding: 0px 0px;
  font-size: 0.9em;
  min-height: 56;
}

.expansionPanelSummary:expanded {
  min-height: 56;
}

.grayDot {
  height: 25px;
  width: 25px;
  background-color: gray;
  border-radius: 50%;
  border-style: solid;
  border-width: thin;
  display: inline-block;
}

.redDot {
  height: 25px;
  width: 25px;
  background-color: #e74c3c;
  border-radius: 50%;
  border-style: solid;
  border-width: thin;
  display: inline-block;
}

.redDotSpareParts {
  height: 12px;
  width: 12px;
  background-color: #e74c3c;
  border-radius: 50%;
  border-style: solid;
  border-width: thin;
  display: inline-block;
}

.yellowDot {
  height: 25px;
  width: 25px;
  background-color: #f1c40f;
  border-radius: 50%;
  border-style: solid;
  border-width: thin;
  display: inline-block;
}

.yellowDotSpareParts {
  height: 12px;
  width: 12px;
  background-color: #f1c40f;
  border-radius: 50%;
  border-style: solid;
  border-width: thin;
  display: inline-block;
}

.greenDot {
  height: 25px;
  width: 25px;
  background-color: #2ecc71;
  border-radius: 50%;
  border-style: solid;
  border-width: thin;
  display: inline-block;
}

.greenDotSpareParts {
  height: 12px;
  width: 12px;
  background-color: #2ecc71;
  border-radius: 50%;
  border-style: solid;
  border-width: thin;
  display: inline-block;
}

.RowUpper td{
  font-size: 25px !important;
}

.RowDatas td{
  font-size: 19px !important;
}


.nav-link{
  color: var(--body-link-color);
}




.nav-link:hover{
  color: #35ca58;
}
.app_container {
  padding: 15px;
}

.button {
  border-color: white;
  background-color: #103a21;
}

.logo {
  margin: 10px;
  margin-top: 200px;
  margin-bottom: 200px;
  border-width: 0px;
  border: 
}

.logosFirst {
  margin-left: 10%;
  margin-top: 5%;
  width: 100%;
}

.logosSecond {
  margin-left: 5%;
  margin-top: 5%;
  width: 100%;
}

.reportSection {
  margin-top: 20px;
}

.reportSectionHeader {
  width: 100%;
  height: 3em;
  background-color: #13aa4c;
  color: white;
  font-weight: bold;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 10px;
  font-size: 1.2em;

}


.omsTable {
  width: 100%;
  text-align: center;
  color: black;
  border-style: solid;
  border-color: black;
  margin-bottom: 60px;
  display: inline-table;

}
.omsTable th {
  color: white;
  background-color: #103a21;
  font-size: 1.1em;
  border-style: solid;
  border-color: black;
  border-width: 1px 1px 1px 1px;
  padding: 10px;
  border-style: solid;
  border-color: black;
  border-width: 1px 1px 1px 1px;

}


.omsTable td {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 0.8em;
  border-style: solid;
  border-color: black;
  border-width: 1px 1px 1px 1px;

}

.omsTable tr:nth-child(even) {
  font-weight: bold;
  background-color: #C0D9AF;
}

.omsTable tr:nth-child(odd) {
  font-weight: bold;
  background-color: #FFFFFF;
}

.app_container {
  padding: 15px;
}

.button {
  background-color: white;
  color: black;
  font-weight: bold;
  font-size: 20px;
  border-radius: 0;
  border: white;
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-self: center;
          align-self: center;
  width:100%
}

.title {
  background-color: white;
  color: white;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 0;
  border: white;
}

.reportSection {
  margin-top: 20px;
}

.reportSectionHeader {
  width: 100%;
  height: 3em;
  background-color: #13aa4c;
  color: white;
  font-weight: bold;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 10px;
  font-size: 1.2em;
}

.summaryBox {
  display: inline-block;
  text-align: center;
  margin: 1em;
  width: 27%;
  height: 5em;
  border-color: rgba(50, 50, 50, 0.77);
  box-shadow: 6px 6px 6px 6px rgba(50, 50, 50, 0.77);
}

.boxTable {
  font-size: 1em;
  text-align: center;
  color: black;
  background-color: white;
}

.summaryTable {
  text-align: center;
  font-size: 10px;
  color: black;

  border-style: solid;
  border-color: black;

}

.summaryHeader {
  font-size: 10px;
  background-color: #27ae60;
  color: white;

  border-style: solid;
  border-color: black;
}

.summaryHeader th, .summarySubheader th {
  border-style: solid;
  border-color: black;
  border-width: 1px 1px 1px 1px;

  padding: 10px;
}

.summarySubheader {
  font-size: 10px;
  background-color: #f1c40f;
  color: black;

  border-style: solid;
  border-color: black;
}

.summarySubheader th:first-child {
  text-align: left;
}

.summaryTable tbody tr td {
  border-style: solid;
  border-color: black;
  border-width: 1px 1px 1px 1px;
}

.summaryGreenRow {
  background-color: #C0D9AF;
  font-size: 10px;
}

.summaryGreenRow td:first-child {
  text-align: left;
  padding-left: 10px;
  font-size: 10px;
}

.summaryGreenRow td:last-child {
  font-size: 10px;
  font-weight: bold;
  font-size: 10px;
}

.summaryLightgreenRow {
  background-color: #ecf0f1;
  color: #16a085;
  font-weight: bold;
  font-size: 10px;
}

.summaryLightgreenRow td:first-child {
  background-color: white;
  text-align: left;
  padding-left: 10%;
  font-size: 10px;
}

.summaryLightgreenRow td:last-child {
  font-size: 10px;
}

.summarySubrow {
  background-color: #ecf0f1;
}

.summarySubrow td:first-child {
  background-color: white;
  text-align: left;
  padding-left: 10%;
}

.summarySubrow td:last-child {
  font-size: 10px;
  font-weight: bold;
}

.redDot {
  height: 25px;
  width: 25px;
  background-color: #e74c3c;
  border-radius: 50%;
  border-style: solid;
  border-width: thin;
  display: inline-block;
}

.yellowDot {
  height: 25px;
  width: 25px;
  background-color: #f1c40f;
  border-radius: 50%;
  border-style: solid;
  border-width: thin;
  display: inline-block;
}

.greenDot {
  height: 25px;
  width: 25px;
  background-color: #2ecc71;
  border-radius: 50%;
  border-style: solid;
  border-width: thin;
  display: inline-block;
}



.app_container {
  padding: 15px;
}

.link {
  margin-left: 1100px;
}

.TableHub {
  border-collapse: collapse;
  width: 100%
}

.TableHub td th {
  border: 3px solid #ddd;
  padding: 8px;
}

.TableHub td {
  border: 3px solid #ddd;
  color: black;
  text-align: center;
  font-size: 12px;
}

.TableHub th {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  background-color: #4CAF50;
  color: white;
  border: 3px solid #ddd;
  font-size: 15px;
}

.TableHub tr:nth-child(even){
  background-color: #f2f2f2;
}

.TableHub tr:hover {
  background-color: #ddd;
}
/* Color Helpers */
.has-text-success {
    color: #48c774;
  }
  .has-text-success * {
    color: #48c774;
  }
  /* Grid Layout Based on Bulma */
  .columns {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    margin-top: -0.75rem;
  }
  .columns.columns.is-centered {
    -webkit-justify-content: center;
            justify-content: center;
  }
  .column.is-2 {
    width: 16.66667%;
  }
  .column {
    display: block;
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-flex-shrink: 1;
            flex-shrink: 1;
    padding: 0.75rem;
  }
  /* Box element based on Bulma */
  .box {
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
    color: #4a4a4a;
    display: block;
    padding: 1.25rem;
    margin-bottom: 20px;
  }
  /* Main button Component */
  .icon-btn {
    transition: 0.25s ease;
    font-family: apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    min-height: 7rem;
    width: 90%;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
  }
  .icon-btn .icon {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: 2.5rem;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    margin: auto;
    padding: 0.5rem;
  }
  .icon-btn.ranking {
    color: #00000099;
    width: 100% !important;
    height: 3px !important;
    min-height: 0px !important;
    -webkit-flex-direction:inherit !important;
            flex-direction:inherit !important
  }
  
  .icon-btn .icon-ranking {
    -webkit-justify-content: center;
            justify-content: center;
    font-size: 17px;
    -webkit-flex-grow: 1;
            flex-grow: 1;   
    
  }
  .rankingIcon{
    margin-right: 15px;
  }
  .icon-btn.ranking * {
    color: #00000099;
  }
  .icon-btn:hover {
    cursor: pointer;
    transition: 0.25s ease;
  }
  .icon-btn:hover * {
    color: #fafafa;
    transition: 0.25s ease;
  }
  .icon-btn:hover.has-text-success {
    background-color: #48c774;
  }
  .icon-btn:active.has-text-success {
    background-color: #48c774;
  }
  .icon-btn:hover.ranking {
    background-color: #00000099;
  }
  .divSelection{
    margin: 0 10% !important
  }
  .bottomSelection{
    margin: 0 10% !important
  }
  .loadingSelection{
    margin: 0 10% !important
  }
  .colSelection{
    padding: 0% !important;
  }
  
  @media only screen and (min-width:769px) { 
  .selectBox{
    margin: 0 auto !important;
    margin-top:  25px !important;
  }
}

  .titleRemuneration{
    width: 130%;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline
 {
   border-color: #48c774 !important;
 }

 #demo-simple-select-outlined{
   width: 150px !important;
 }
  @media only screen and (max-width:768px) {  
    .titleRemuneration{
      margin-left:60% !important;
    }
    .selectBox{
      margin-top:  25px !important;
    }
  }




.app_container {
  padding: 15px;
  text-align: center;
}

.material-icons.greenFlash {
  color: #37b766;
  font-size:120px;
  size: 15px;
}

.material-icons.yellow {
  color: #f3ff54;
  margin-right: auto;
}

.material-icons.red {
  color: #f72525;
  margin-right: auto;
}

.customModal {
  /* background-color: #191919; */
  color: #00c853;
  font-size: 1em;
  width: 500em;
  /* widows: 200em; */
  -webkit-align-items: stretch;
          align-items: stretch;
}

.modalTitle {
  color: #00c853;
  font-size: 1.3em;
  font-weight: bold;
}

.modalCloseButton {
  font-size: 0.8em;
  background-color: #fff;
  font-weight: lighter;
  border: #191919;
  color: #00c853;
  text-align: center;
}


.cadenceTable {
  width: 100%;

  text-align: center;

  font-size: 30px;

  color: black;
}

.cadenceTable th {
  color: white;
  background-color: #2c3e50;

  padding: 10px;
}

table, th, td {
    border-collapse: collapse;
}

td {
  padding-top: 10px;
  padding-bottom: 10px;
}

.cadenceTable td:first-child {
  font-weight: bold;
}




.app_container {
  padding: 15px;
  text-align: center;
  /* font-family: 'GoBold'; */
}

.customModal {
  /* background-color: #191919; */
  color: #00c853;
  font-size: 1em;
  width: 500em;
  /* widows: 200em; */
  -webkit-align-items: stretch;
          align-items: stretch;
}

.modalTitle {
  color: #00c853;
  font-size: 1.3em;
  font-weight: bold;
}

.modalCloseButton {
  font-size: 0.8em;
  background-color: #fff;
  font-weight: lighter;
  border: #191919;
  color: #00c853;
  text-align: center;
}


.cadenceTable {
  width: 100%;

  text-align: center;

  font-size: 30px;

  color: black;
}

.cadenceTable th {
  color: white;
  background-color: #2c3e50;

  padding: 10px;
}

table, th, td {
    border-collapse: collapse;
}

td {
  padding-top: 10px;
  padding-bottom: 10px;
}

.cadenceTable td:first-child {
  font-weight: bold;
}




.app_container {
  padding: 15px;
  text-align: center;
}

.customModal {
  /* background-color: #191919; */
  color: #00c853;
  font-size: 1em;
  width: 500em;
  /* widows: 200em; */
  -webkit-align-items: stretch;
          align-items: stretch;
}

.modalTitle {
  color: #00c853;
  font-size: 1.3em;
  font-weight: bold;
}

.modalCloseButton {
  font-size: 0.8em;
  background-color: #fff;
  font-weight: lighter;
  border: #191919;
  color: #00c853;
  text-align: center;
}


.cadenceTable {
  width: 100%;

  text-align: center;

  font-size: 30px;

  color: black;
}

.cadenceTable th {
  color: white;
  background-color: #2c3e50;

  padding: 10px;
}

table, th, td {
    border-collapse: collapse;
}

td {
  padding-top: 10px;
  padding-bottom: 10px;
}

.cadenceTable td:first-child {
  font-weight: bold;
}

.app_container {
  padding: 15px;
}

.spacingDash{
    opacity: 0;
}

.cadenceTable {
  width: 100%;
  text-align: center;
  font-size: 1.5em;
  color: black;
  margin-bottom: 80px;
}

.cadenceTable th {
  color: white;
  background-color: #103a21;
  padding: 10px;
}



/* .cadenceTable tr:nth-child(7){
  border-top-style: solid;
  border-top-color: #2c3e50;
} */
/*
.cadenceTable tr:nth-child(3){
  background-color: #b7c0ce;
  border-bottom-style: groove;
}

/*
Personaliza a linha de Total
*/
/* .cadenceTable tr:nth-child(3) td:nth-child(1){
  background-color: #ffffff;
}

.cadenceTable tr:nth-child(3) td:nth-child(7){
  background-color: #ffffff;
}

.cadenceTable tr:nth-child(3) td:nth-child(11){
  background-color: #ffffff;
} */ */



.slaOplTable {
  border-collapse: collapse;
  width: 100%;
}

.slaOplTable td th {
  border: 3px solid #ddd;
  padding: 8px;
}

.slaOplTable td {
  padding: 10px 10px 10px 10px;
  border: 3px solid #ddd;
  color: black;
  text-align: center;
}

.slaOplTable th {
  padding: 20px 40px 20px 40px;
  text-align: center;
  background-color: #4CAF50;
  color: white;
  border: 3px solid #ddd;
}


table, th, td {
  border-collapse: collapse;
}

td {
  padding-top: 5px;
  padding-bottom: 5px;
}

.cadenceTableSpacing {
  width: 10px;
}

.cadenceTable td:first-child {
  font-weight: bold;
}

.tdHighlight {
  font-weight: bold;
}

.tdHighlightRed {
  font-weight: bold;
  background-color: #ff5e57;
  color: white;
}

.totalLabel {
  color: black;
}

.stoneLabel {
  color: #44bd32;
}

.elavonLabel {
  color: #2980b9;
}

.stoneLightLabel {
  color: #356E1C;
}

.stoneGrayLabel {
  color: #8A8E89;
}

.stoneDarkBlueLabel {
  color: #2c3e50;
}

.reportSection {
  margin-top: 20px;
}

.reportSectionHeader {
  height: 40px;
  width: 100%;
  background-color: #13aa4c;
  color: white;
  font-weight: bold;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 10px;
  font-size: 1.2em;
}

.machineMachineConsumptionTable {
  border-collapse: collapse;
}

.machineMachineConsumptionTable td th {
  border: 3px solid #ddd;
  padding: 8px;
}

.machineMachineConsumptionTable td {
  padding: 10px 10px 10px 10px;
  border: 3px solid #ddd;
  color: black;
  text-align: center;
}

.machineMachineConsumptionTable th {
  padding: 20px 40px 20px 40px;
  text-align: center;
  background-color: #4CAF50;
  color: white;
  border: 3px solid #ddd;
}

.machineMachineConsumptionTable tr:nth-child(even){
  background-color: #f2f2f2;
}

.machineMachineConsumptionTable tr:hover {
  background-color: #ddd;
}

.selfLogisticsMap {
  border: 2px solid #18641e;
  display: inline-block;
  margin: 0.5em 0.3em 0.5em 0.3em;
  padding: 2px;
  text-align: center;
  font-weight: bold;
  font-size: 13;
  color: #18641e;
  background-color: white;
}

.selfLogisticsMap:hover {
  background-color: #D6EACE;
}

.dailyAverageAgingTableLeft {
  color: #f7fcff;
  background-color: #00b72f;
  padding: 5px;
  border: 3px;
  font-weight: bold;
  border-color: gray;
  -webkit-align-items: center;
          align-items: center;
}

.dailyAverageAgingTableRight {
  color: black;
  border: 3px;
  padding: 0 25px 0 25px;
  font-weight: bold;
  border-color: gray;
  -webkit-align-items: center;
          align-items: center;
}

.loadingMessage {
  font-size: 1.2em;
}

.expansionPanel {
  margin-bottom: '5px';
}

.expansionPanelSummary {
  height: 4.5px; 
  background-color:#28a745; 
  color: white; 
  margin: 0px 0px; 
  padding: 0px 0px;
  font-size: 0.9em;
  min-height: 56;
}

.expansionPanelSummary:expanded {
  min-height: 56;
}

.app_container {
  padding: 15px;
}

.spacingDash{
    opacity: 0;
}

.cadenceTable {
  width: 100%;
  text-align: center;
  font-size: 1.5em;
  color: black;
  margin-bottom: 80px;
}

.cadenceTable th {
  color: white;
  background-color: #103a21;
  padding: 10px;
}



/* .cadenceTable tr:nth-child(7){
  border-top-style: solid;
  border-top-color: #2c3e50;
} */
/*
.cadenceTable tr:nth-child(3){
  background-color: #b7c0ce;
  border-bottom-style: groove;
}

/*
Personaliza a linha de Total
*/
/* .cadenceTable tr:nth-child(3) td:nth-child(1){
  background-color: #ffffff;
}

.cadenceTable tr:nth-child(3) td:nth-child(7){
  background-color: #ffffff;
}

.cadenceTable tr:nth-child(3) td:nth-child(11){
  background-color: #ffffff;
} */ */



.slaOplTable {
  border-collapse: collapse;
  width: 100%;
}

.slaOplTable td th {
  border: 3px solid #ddd;
  padding: 8px;
}

.slaOplTable td {
  padding: 10px 10px 10px 10px;
  border: 3px solid #ddd;
  color: black;
  text-align: center;
}

.slaOplTable th {
  padding: 20px 40px 20px 40px;
  text-align: center;
  background-color: #4CAF50;
  color: white;
  border: 3px solid #ddd;
}


table, th, td {
  border-collapse: collapse;
}

td {
  padding-top: 5px;
  padding-bottom: 5px;
}

.cadenceTableSpacing {
  width: 10px;
}

.cadenceTable td:first-child {
  font-weight: bold;
}

.tdHighlight {
  font-weight: bold;
}

.tdHighlightRed {
  font-weight: bold;
  background-color: #ff5e57;
  color: white;
}

.totalLabel {
  color: black;
}

.stoneLabel {
  color: #44bd32;
}

.elavonLabel {
  color: #2980b9;
}

.stoneLightLabel {
  color: #356E1C;
}

.stoneGrayLabel {
  color: #8A8E89;
}

.stoneDarkBlueLabel {
  color: #2c3e50;
}

.reportSection {
  margin-top: 20px;
}

.reportSectionHeader {
  height: 40px;
  width: 100%;
  background-color: #13aa4c;
  color: white;
  font-weight: bold;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 10px;
  font-size: 1.2em;
}

.machineMachineConsumptionTable {
  border-collapse: collapse;
}

.machineMachineConsumptionTable td th {
  border: 3px solid #ddd;
  padding: 8px;
}

.machineMachineConsumptionTable td {
  padding: 10px 10px 10px 10px;
  border: 3px solid #ddd;
  color: black;
  text-align: center;
}

.machineMachineConsumptionTable th {
  padding: 20px 40px 20px 40px;
  text-align: center;
  background-color: #4CAF50;
  color: white;
  border: 3px solid #ddd;
}

.machineMachineConsumptionTable tr:nth-child(even){
  background-color: #f2f2f2;
}

.machineMachineConsumptionTable tr:hover {
  background-color: #ddd;
}

.selfLogisticsMap {
  border: 2px solid #18641e;
  display: inline-block;
  margin: 0.5em 0.3em 0.5em 0.3em;
  padding: 2px;
  text-align: center;
  font-weight: bold;
  font-size: 13;
  color: #18641e;
  background-color: white;
}

.selfLogisticsMap:hover {
  background-color: #D6EACE;
}

.dailyAverageAgingTableLeft {
  color: #f7fcff;
  background-color: #00b72f;
  padding: 5px;
  border: 3px;
  font-weight: bold;
  border-color: gray;
  -webkit-align-items: center;
          align-items: center;
}

.dailyAverageAgingTableRight {
  color: black;
  border: 3px;
  padding: 0 25px 0 25px;
  font-weight: bold;
  border-color: gray;
  -webkit-align-items: center;
          align-items: center;
}

.loadingMessage {
  font-size: 1.2em;
}

.expansionPanel {
  margin-bottom: '5px';
}

.expansionPanelSummary {
  height: 4.5px; 
  background-color:#28a745; 
  color: white; 
  margin: 0px 0px; 
  padding: 0px 0px;
  font-size: 0.9em;
  min-height: 56;
}

.expansionPanelSummary:expanded {
  min-height: 56;
}




.app_container {
  /* padding: 15px; */
  margin-top: 2em;
  text-align: center;
}

.iconRoutes{
  padding-right: inherit;
  width: 80px;

  }

.redDot {
  height: 25px;
  width: 25px;
  background-color: #e74c3c;
  border-radius: 50%;
  border-style: solid;
  border-width: thin;
  display: inline-block;
}

.yellowDot {
  height: 25px;
  width: 25px;
  background-color: #f1c40f;
  border-radius: 50%;
  border-style: solid;
  border-width: thin;
  display: inline-block;
}

.greenDot {
  height: 25px;
  width: 25px;
  background-color: #2ecc71;
  border-radius: 50%;
  border-style: solid;
  border-width: thin;
  display: inline-block;
}



.app_container {
  padding: 15px;
}

.link {
  margin-left: 1100px;
}

.TableHub {
  border-collapse: collapse;
  width: 100%
}

.TableHub td th {
  border: 3px solid #ddd;
  padding: 8px;
}

.TableHub td {
  border: 3px solid #ddd;
  color: black;
  text-align: center;
  font-size: 12px;
}

.TableHub th {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  background-color: #4CAF50;
  color: white;
  border: 3px solid #ddd;
  font-size: 15px;
}

.TableHub tr:nth-child(even){
  background-color: #f2f2f2;
}

.TableHub tr:hover {
  background-color: #ddd;
}
.colMTD {
  text-align: center;
  border: 1px solid;
}

.rowMTD {
  width: 95% !important;
  margin-left: 2.5% !important;
  margin-bottom: 10px !important;
}

.simbolCircle {
  background: lightblue;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.btnExportAll {
  margin-left: 15% !important;
}

.divFiltro {
  margin-left: 13% !important;
}

.makeStyles-root-166 {
  margin-top: 10px !important;
  margin-right: 20px !important;
}

.MuiTableCell-head {
  font-size: 16px !important;
}

.app_container {
  padding: 15px;
}

.button {
  background-color: white;
  color: black;
  font-weight: bold;
  font-size: 20px;
  border-radius: 0;
  border: white;
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-self: center;
          align-self: center;
  width:100%
}

.title {
  background-color: white;
  color: white;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 0;
  border: white;
}

.reportSection {
  margin-top: 20px;
}

.reportSectionHeader {
  width: 100%;
  height: 3em;
  background-color: #13aa4c;
  color: white;
  font-weight: bold;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 10px;
  font-size: 1.2em;
}

.summaryBox {
  display: inline-block;
  text-align: center;
  margin: 1em;
  width: 27%;
  height: 5em;
  border-color: rgba(50, 50, 50, 0.77);
  box-shadow: 6px 6px 6px 6px rgba(50, 50, 50, 0.77);
}

.boxTable {
  font-size: 1em;
  text-align: center;
  color: black;
  background-color: white;
}

.summaryTable {
  text-align: center;
  font-size: 10px;
  color: black;

  border-style: solid;
  border-color: black;

}

.summaryHeader {
  font-size: 10px;
  background-color: #27ae60;
  color: white;

  border-style: solid;
  border-color: black;
}

.summaryHeader th, .summarySubheader th {
  border-style: solid;
  border-color: black;
  border-width: 1px 1px 1px 1px;

  padding: 10px;
}

.summarySubheader {
  font-size: 10px;
  background-color: #f1c40f;
  color: black;

  border-style: solid;
  border-color: black;
}

.summarySubheader th:first-child {
  text-align: left;
}

.summaryTable tbody tr td {
  border-style: solid;
  border-color: black;
  border-width: 1px 1px 1px 1px;
}

.summaryGreenRow {
  background-color: #C0D9AF;
  font-size: 10px;
}

.summaryGreenRow td:first-child {
  text-align: left;
  padding-left: 10px;
  font-size: 10px;
}

.summaryGreenRow td:last-child {
  font-size: 10px;
  font-weight: bold;
  font-size: 10px;
}

.summaryLightgreenRow {
  background-color: #ecf0f1;
  color: #16a085;
  font-weight: bold;
  font-size: 10px;
}

.summaryLightgreenRow td:first-child {
  background-color: white;
  text-align: left;
  padding-left: 10%;
  font-size: 10px;
}

.summaryLightgreenRow td:last-child {
  font-size: 10px;
}

.summarySubrow {
  background-color: #ecf0f1;
}

.summarySubrow td:first-child {
  background-color: white;
  text-align: left;
  padding-left: 10%;
}

.summarySubrow td:last-child {
  font-size: 10px;
  font-weight: bold;
}

.expansionPanel {
  margin-bottom: '5px';
}

.expansionPanelSummary {
  height: 4.5px;
  background-color:#28a745;
  color: white;
  margin: 0px 0px;
  padding: 0px 0px;
  font-size: 0.9em;
  min-height: 56;
}

.expansionPanelSummary:expanded {
  min-height: 56;
}


.grayDot {
  height: 25px;
  width: 25px;
  background-color: gray;
  border-radius: 50%;
  border-style: solid;
  border-width: thin;
  display: inline-block;
}

.redDot {
  height: 25px;
  width: 25px;
  background-color: #e74c3c;
  border-radius: 50%;
  border-style: solid;
  border-width: thin;
  display: inline-block;
}

.redDotSpareParts {
  height: 12px;
  width: 12px;
  background-color: #e74c3c;
  border-radius: 50%;
  border-style: solid;
  border-width: thin;
  display: inline-block;
}

.yellowDot {
  height: 25px;
  width: 25px;
  background-color: #f1c40f;
  border-radius: 50%;
  border-style: solid;
  border-width: thin;
  display: inline-block;
}

.yellowDotSpareParts {
  height: 12px;
  width: 12px;
  background-color: #f1c40f;
  border-radius: 50%;
  border-style: solid;
  border-width: thin;
  display: inline-block;
}

.greenDot {
  height: 25px;
  width: 25px;
  background-color: #2ecc71;
  border-radius: 50%;
  border-style: solid;
  border-width: thin;
  display: inline-block;
}

.greenDotSpareParts {
  height: 12px;
  width: 12px;
  background-color: #2ecc71;
  border-radius: 50%;
  border-style: solid;
  border-width: thin;
  display: inline-block;
}

.expansionPanel {
    margin-bottom: '5px';
  }
  
  .tabLink {
    color: white !important;
    background-color: #28a745 !important;
    margin-left: 10px;
  }

  .topBox{
    margin-top:0% !important;
    margin-bottom:2% !important;
  }
 
  .nav-link.active {
    color: #28a745 !important;
    background-color: white !important;
  }
  
  .graphDiv{
    width: 100%;
   }

  .graphDivDuo{
   width: 100%;
  }


  @media (max-width: 991px){
    .graphDiv{
      width: 90% !important;
      margin-left:2% !important;
     }
  
    .graphDivDuo{
     width: 90%;
     margin-left:2% !important;
    }
    .divGraph{
      margin-left: 2% !important;
    }

    .topBox{
      margin-top:0% !important;
      margin-bottom:0% !important;
    }
  }

  @media (max-width: 400px){
    .tabLink {
      width: 26% !important;
    }
  }

  .redDot {
    height: 25px;
    width: 25px;
    background-color: #e74c3c;
    border-radius: 50%;
    border-style: solid;
    border-width: thin;
    display: inline-block;
  }
  
  .redDotSpareParts {
    height: 12px;
    width: 12px;
    background-color: #e74c3c;
    border-radius: 50%;
    border-style: solid;
    border-width: thin;
    display: inline-block;
  }
  
  .yellowDot {
    height: 25px;
    width: 25px;
    background-color: #f1c40f;
    border-radius: 50%;
    border-style: solid;
    border-width: thin;
    display: inline-block;
  }
  
  .yellowDotSpareParts {
    height: 12px;
    width: 12px;
    background-color: #f1c40f;
    border-radius: 50%;
    border-style: solid;
    border-width: thin;
    display: inline-block;
  }
  
  .greenDot {
    height: 25px;
    width: 25px;
    background-color: #2ecc71;
    border-radius: 50%;
    border-style: solid;
    border-width: thin;
    display: inline-block;
  }
  
  .greenDotSpareParts {
    height: 12px;
    width: 12px;
    background-color: #2ecc71;
    border-radius: 50%;
    border-style: solid;
    border-width: thin;
    display: inline-block;
  }
  
.greenDot {
  height: 13px;
  width: 13px;
  margin-top: 10px;
}

.redDot {
  height: 13px;
  width: 13px;
}
.carousel-inner {
  min-height: 330px !important;
}

.greenDot {
  height: 13px;
  width: 13px;
  margin-top: 10px;
}

.redDot {
  height: 13px;
  width: 13px;
}
.carousel-control-prev {
  width: 10% !important;
  height: 330px !important;
}

.carousel-control-next {
  width: 10% !important;
  height: 330px !important;
}

.modal-dialog {
  /* background-color: #191919; */
  color: #00c853;
  font-size: 1em;
  width: 90vw !important;
  /* widows: 200em; */
  -webkit-align-items: center;
          align-items: center;
}

.notificationCircle {
  background: #0cb14b;
  width: 22px;
  height: 22px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  color: white;
  font-size: 15px;
  font-weight: bold;
  border-radius: 50px;
  margin-left: 40%;
  margin-top: -10%;
}

.rowTable {
  width: 100%;
}

.exportAll {
  margin-left: 1%;
  margin-bottom: 1%;
  cursor: pointer;
  margin-left: 93%;
}

.btnExportAll {
  color: gray;
  font-family: "GoBold" !important;
  font-weight: bolder !important;
}

.btnExportAll:hover {
  color: #0cb14b !important;
  border-color: #0cb14b !important;
}

.btnExportAll:active {
  color: #0cb14b !important;
  font-size: 15px !important;
  border-color: #0cb14b !important;
}

.btnExportAll:focus {
  color: #0cb14b !important;
  border-color: #0cb14b !important;
}

.btnExport {
  cursor: pointer;
  color: gray;
}

.btnExport:hover {
  color: #0cb14b !important;
}

.btnExport:active {
  color: #0cb14b !important;
  font-size: 25px;
}

.waringTitle {
  font-size: small;
  font-weight: bold;
}

.tableTitle {
  /* margin-bottom: 18px; */
  margin-top: -1px;
  margin-left: 25px;
  color: #00000099;
}

.sideBarCard {
  min-width: 80px;
  max-width: 80px;
  max-height: 24px;
  text-align: center;
}

.sideBarSenninha {
  max-height: 50px;
  min-width: 108%;
  max-width: 108%;
}

.cardTitle {
  margin-top: 7% !important;
  color: #00000099;
  color: black;
  font-weight: bolder;
  font-family: "GoBold" !important;
}

.cardText {
  padding: 0px !important;
}

/* .MuiTableCell-body{
  font-weight: bolder !important;
} */

.subtitleTable {
  color: white !important;
  background-color: grey !important;
}

.BoxTitle {
  font-size: 16px;
  color: black;
  font-weight: bolder;
  margin-bottom: 0px !important;
  margin-top: 10px !important;
  font-family: "GoBold" !important;
}

.BoxText {
  font-size: 17px;
  color: black;
  font-weight: bolder;
  font-family: "GoBold" !important;
}

.iconCard {
  width: 20px !important;
  margin-top: -2% !important;
}

.iconSenninha {
  width: 40px !important;
  margin-top: 10px;
}
.chartjs-render-monitor {
  height: 330px !important;
}

.senninhaContent {
  padding: 0px !important;
  text-align: center;
}

.iconCardSmall {
  width: 20px !important;
  margin-top: -4% !important;
}

.DistrictRow {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.material-icons.portalFlash {
  color: #37b766;
  /* font-size:120px;
  size: 15px; */
}

#sidebar {
  min-width: 220px;
}

#content {
  margin-left: 3%;
  margin-top: 8px;
}

.app_container {
  padding: 15px;
  text-align: center;
}

.material-icons.greenBolt {
  color: #37b766;
  font-size: 2.4em;
  /* size: 30px; */
  width: 40px !important;
  margin-top: 10px;
}

.material-icons.yellow {
  color: #f3ff54;
  margin-right: auto;
}

.material-icons.red {
  color: #f72525;
  margin-right: auto;
}

.customModal {
  /* background-color: #191919; */
  color: #00c853;
  font-size: 1em;
  width: 500em;
  /* widows: 200em; */
  -webkit-align-items: stretch;
          align-items: stretch;
}

.modalTitle {
  color: #00c853;
  font-size: 1.3em;
  font-weight: bold;
}

.modalCloseButton {
  font-size: 0.8em;
  background-color: #fff;
  font-weight: lighter;
  border: #191919;
  color: #00c853;
  text-align: center;
}

.cadenceTable {
  width: 100%;

  text-align: center;

  font-size: 30px;

  color: black;
}

.cadenceTable th {
  color: white;
  background-color: #2c3e50;

  padding: 10px;
}

table,
th,
td {
  border-collapse: collapse;
}

td {
  padding-top: 10px;
  padding-bottom: 10px;
}

.cadenceTable td:first-child {
  font-weight: bold;
}

@media only screen and (max-width: 990px) {
  #sidebar {
    margin-left: 25%;
  }
}

@media only screen and (max-width: 1199px) {
  .sideBarSenninha {
    max-height: 70px;
    min-width: 80%;
    max-width: 80%;
    margin-bottom: 5%;
  }
}

@media only screen and (max-width: 450px) {
  #sidebar {
    margin-left: 10%;
  }

  .exportAll {
    margin-left: 5%;
    margin-bottom: 1%;
    cursor: pointer;
  }
  .DistrictRow {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}

@media only screen and (max-width: 350px) {
  .sideBarSenninha {
    max-height: 70px;
    min-width: 97%;
    max-width: 97%;
    margin-bottom: 5%;
  }
  #sidebar {
    margin-left: 5% !important;
  }
}

* {
    margin: 0;
    padding: 0;
}
.container{
    /* display: flex; */
    /* justify-content: 'center'; */
    background-color: #000000 !important;
    height: 200vh;
    width: 130vw !important;
    margin: 0;
    margin-right: 0 !important;
    padding: 0;
}

.head{
    display: grid;
    grid-template-columns: repeat(100, 1vw);
    background-color: #000000 !important;
    /* grid-template-rows: 2vh 8vh 10vh 20vh 10vh 10vh 20vh 20vh; */
    grid-template-rows: repeat(200, 1vh);
    width: 100vw;
    height: 200vh;
}

.logoHunters{
    grid-row: 2;
    grid-column: 36;
    width: 28vw;
    /* background-color: indigo; */
}

.logoText{
    width: 32vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    color: white;
    grid-column: 40;
    grid-row: 47;
    /* background-color: green; */
}

.podiumIcon{
    width: 20vw;
    /* background-color: green; */
    /* grid-row: 5; */
    grid-row: 61;
    grid-column: 15;
}

.trophyIcon {
    width: 2em;
}

.medalIcon {
    width: 1.5em;
}

.firstGroup{
    display: -webkit-flex;
    display: flex;
    width: 18vh;
    text-align: center;
    -webkit-justify-content: center;
            justify-content: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    word-wrap: break-word;
    /* background-color: green; */
    color: white;
    /* grid-row: 6; */
    grid-row: 64;
    grid-column: 21;
}

.secondGroup{
    display: -webkit-flex;
    display: flex;
    width: 18vh;
    text-align: center;
    -webkit-justify-content: center;
            justify-content: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    word-wrap: break-word;
    /* background-color: green; */
    color: white;
    /* grid-row: 7; */
    grid-row: 74;
    grid-column: 12;
    font-size: 0.9em;
}

.thirdGroup{
    display: -webkit-flex;
    display: flex;
    width: 18vh;
    text-align: center;
    -webkit-justify-content: center;
            justify-content: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    word-wrap: break-word;
    /* background-color: green; */
    color: white;
    /* grid-row: 7; */
    grid-row: 74;
    grid-column: 29;
    font-size: 0.9em;
}


.podiumIconPeople{
    width: 20vw;
    /* background-color: green; */
    /* grid-row: 6; */
    grid-row: 61;
    grid-column: 65;
}

.firstPerson{
    display: -webkit-flex;
    display: flex;
    width: 18vh;
    text-align: center;
    -webkit-justify-content: center;
            justify-content: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    word-wrap: break-word;
    /* background-color: green; */
    color: white;
    /* grid-row: 6; */
    grid-row: 61;
    grid-column: 71;
}

.secondPerson{
    display: -webkit-flex;
    display: flex;
    width: 14vh;
    text-align: center;
    -webkit-justify-content: center;
            justify-content: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    word-wrap: break-word;
    /* background-color: green; */
    color: white;
    /* grid-row: 7; */
    grid-row: 71;
    grid-column: 64;
    font-size: 0.9em;
}

.thirdPerson{
    display: -webkit-flex;
    display: flex;
    width: 14vh;
    text-align: center;
    -webkit-justify-content: center;
            justify-content: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    word-wrap: break-word;
    /* background-color: green; */
    color: white;
    /* grid-row: 7; */
    grid-row: 71;
    grid-column:79;
    font-size: 0.9em;
}

.firstTableLeft{
    background-color: whitesmoke;
    width:40vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    grid-row: 110;
    grid-column:5;
}

.firstTableRight{
    background-color: whitesmoke;
    width:40vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    grid-row: 110;
    grid-column:55;
}

.secondTableLeft{
    background-color: whitesmoke;
    width:40vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    grid-row: 160;
    grid-column:5;
}

.secondTableRight{
    background-color: whitesmoke;
    width:40vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    grid-row: 160;
    grid-column:55;
}

@media (max-width: 500px){
    .container{
        height: 220vh;
    }
    .logoHunters{
        grid-column: 21;
        width: 60vw;
    }

    .logoText{
        color: white;
        grid-column: 21;
        grid-row: 40;
        width: 60vw;
    }

    .podiumIcon{
        width: 60vw;
        grid-row: 41;
        grid-column: 21;
    }

    .trophyIcon {
        width: 1em;
        /* background-color: red; */
    }
    
    .firstGroup{
        width: 18vh;
        grid-row: 41;
        grid-column: 35;
    }
    
    .secondGroup{
        width: 18vh;
        grid-row: 49;
        grid-column: 9;
    }
    
    .thirdGroup{
        width: 18vh;
        grid-row: 49;
        grid-column: 55;
    }

    .podiumIconPeople{
        width: 60vw;
        grid-row: 81;
        grid-column: 21;
    }
    
    .firstPerson{
        width: 18vh;
        grid-row: 81;
        grid-column: 35;
    }
    
    .secondPerson{
        width: 14vh;
        grid-row: 85;
        grid-column: 14;
    }
    
    .thirdPerson{
        width: 14vh;
        grid-row: 85;
        grid-column:60;
    }

    .firstTableLeft{
        width:80vw;
        grid-row: 120;
        grid-column:11;
    }
    
    .firstTableRight{
        width:80vw;
        grid-row: 145;
        grid-column:11;
    }
    
    .secondTableLeft{
        width:80vw;
        grid-row: 170;
        grid-column:11;
    }
    
    .secondTableRight{
        width:80vw;
        grid-row: 195;
        grid-column:11;
    }
}

@-webkit-keyframes trophyRote {
    from{
        width: 0;
      }
      to{
        width:14em;
      }
}

@keyframes trophyRote {
    from{
        width: 0;
      }
      to{
        width:14em;
      }
}


.spinner {
    font-family: 'Gobold';
    text-align: center;
}

.main-table {
  display: -webkit-flex;
  display: flex;
  width: 90vw;
  margin-left: 4vw;
  margin-right: 9vw;
  margin-top: 4vw;
  /* margin-right: 0.1em; */
  /* margin-left: 0.3em; */
  -webkit-align-content: center;
          align-content: center;
  justify-self: center;
  -webkit-align-self: center;
          align-self: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.type-dropdown-tipo {
  position: absolute;
  left: 9.11%;
}

.type-dropdown-tipo select {
  padding: 10px;
  width: 100px;
  height: 40px;
  border: none;
  font-family: 'Montserrat';
  font-size: 13px;
  border: 1px solid black;
  background-color: rgb(240, 242, 245);
  box-shadow:0 0 7px gray;
}

.type-dropdown-origem select {
  padding: 10px;
  width: 100px;
  height: 40px;
  border: none;
  font-family: 'Montserrat';
  font-size: 13px;
  border: 1px solid black;
  background-color: rgb(240, 242, 245);
  box-shadow:0 0 7px gray;
}

.type-dropdown-origem {
  position: absolute;
  left: 23.5%;
}

.dropdown-trucker select {
  font-family: 'Montserrat';
  font-size: 12px;
  border: 1px solid black;
  background-color: rgb(240, 242, 245);
}

.button {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 10px;
  width: 150px;
  height: 45px;
  border: none;
  font-family: 'Montserrat';
  font-size: 11px;
  border: 2px solid black;
  background-color: rgb(40, 167, 69);
  position: relative;
  left: 75.80%;
  /* cursor: pointer; */
  color: white;
  box-shadow:0 0 7px gray;
  transition: 0.8s;
  overflow: hidden;
  cursor: default;
}

.unbutton {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 10px;
  width: 120px;
  height: 45px;
  border: none;
  font-family: 'Montserrat';
  font-size: 11px;
  border: 2px solid black;
  background-color: rgb(167, 40, 40);
  position: absolute;
  left: 8.80%;
  margin-top: 0.5em;
  /* cursor: pointer; */
  color: white;
  box-shadow:0 0 7px gray;
  transition: 0.8s;
  overflow: hidden;
  cursor: default;
}

.input[type="checkbox"] {
  height: 10px;
  width: 10px;
}


.simple-table {
    display: grid;
    margin: auto;
    border-radius: 5px;
    /* border: 5px solid black; */
    overflow: hidden;
}

.simple-table .table-head {
  margin: 0;
  font-size: 0.8em;
  font-weight: bold;
  color: white;
  font-family: 'Montserrat';
  background-color: rgb(40, 167, 69);
  text-align: center;
  /* border: 0.1em solid black; */
  /* width: auto; */
  padding: 0.1em;
}

.simple-table .teste {
  margin: 0;
  font-size: 0.8em;
  font-weight: bold;
  color: white;
  font-family: 'Montserrat';
  background-color: rgb(40, 167, 69);
  text-align: center;
  position: '-webkit-sticky';
  position: 'sticky';
  /* border: 0.1em solid black; */
  /* width: auto; */
  padding: 0.1em;
}


.simple-table .row-value {
  padding: 0.6em;
  font-family: 'Montserrat';
  text-align: center;
  border: 0.1em solid lightgray;
  font-size: 0.7em;
  background-color: rgb(240, 242, 245)
}

input[type="checkbox"] {
  background-color: rgb(40, 167, 69);
  padding-right: 6px;
}

.react-tabs {
    -webkit-tap-highlight-color: transparent;
  }
  
  .react-tabs__tab-list {
    border-bottom: 1px solid #aaa;
    margin: 0 0 10px;
    padding: 0;
  }
  
  .react-tabs__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
    font-family: 'Montserrat';
  }
  
  .react-tabs__tab--selected {
    background: #fff;
    border-color: #aaa;
    color: black;
    border-radius: 5px 5px 0 0;
  }
  
  .react-tabs__tab--disabled {
    color: GrayText;
    cursor: default;
  }
  
  .react-tabs__tab:focus {
    box-shadow: 0 0 5px hsl(208, 99%, 50%);
    border-color: hsl(208, 99%, 50%);
    outline: none;
  }
  
  .react-tabs__tab:focus:after {
    content: "";
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    background: #fff;
  }
  
  .react-tabs__tab-panel {
    display: none;
  }
  
  .react-tabs__tab-panel--selected {
    display: block;
  }
  
* {
    margin: 0;
    padding: 0;
    /* font-family: 'GoBold'; */
}

.routesContainer {
width: 100vw!important;
height: 90vh!important;
margin-top: 0;
display: grid;
grid-template-columns: repeat(100, 1vw);
grid-template-rows: repeat(90, 1vh);
margin-left: 0;
}

.mapRoute {
    background-color: blue;
    width: 71vw;
    height: 75vh;
    grid-row: 10;
    grid-column: 28;
    overflow: hidden;

    /* font-size: 1em; */
    /* display: flex;
    align-items: center;
    justify-content: center; */
}

.tableRoute {
    /* background-color: crimson; */
    height: 75vh;
    grid-row: 10;
    grid-column: 0;
    width: 28vw;
    overflow-y: scroll;

    margin-left: 0;
    font-size: 1em;
    /* display: flex;
    align-items: center;
    justify-content: center; */
}

/* .chooseHubs {
    background-color: purple;
    height: 3.5vh;
    width: 17vw;
    grid-row: 2;
    grid-column: 3;

    font-size: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
} */

.extraInfos {
    background-color: yellow;
    height: 7vh;
    width: 60vw;
    grid-row: 5;
    grid-column: 25;

    font-size: 1.5em;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}


.mapContainer {
    width: 71vw !important;
    height: 75vh!important;
}

.chooseBox {
 background-color: #e1eddc;
 grid-row: 20;
 grid-column: 35;   
 width: 30vw;
 height: 50vh;
 border-color: whitesmoke;
 border-radius: 5%;
 border-style: solid;
 display: -webkit-flex;
 display: flex;
 -webkit-align-items: center;
         align-items: center;
 -webkit-justify-content: center;
         justify-content: center; 
}

.imgSenninha{
    margin-top: 0;
    margin-bottom: 1px;
    width: 15vw !important;
    height: 15vh;
}

.chooseIcon{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; 
    
    grid-row: 19;
    grid-column: 34;   
    background-color:#FFFFFF;
    width: 4vw;
    height: 4vw;
    /* width: fit-content;
    height: fit-content; */
    border-radius: 50%;
}

.modalConfig{
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    max-width: 100vw !important;
}

.styles_modal__gNwvD{
    max-width: 100vw !important;
}

.sectionButton{   
    font-size: 1em;
    
    display: inline-block;
    /* padding: 15px 25px; */
    cursor: pointer;
    text-align: center;	
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #4CAF50;
    border: none;
    border-radius: 15px;
    box-shadow: 0 5px #999;
  }

.sectionButton:hover {background-color: #3e8e41}

.sectionButton:active {
  background-color: #3e8e41;
  box-shadow: 0 5px #666;
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
}

.buttonChangeRoutes{
    width: 17vw;
    height: 4vh;
    grid-column: 30;
}

.MuiFormGroup-root.roteirizacaoCheckbox {
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
}

.MuiFormGroup-root.roteirizacaoCheckbox .MuiFormControlLabel-labelPlacementTop{
    font-family: 'GoBold';
    margin: 0 !important;
}

.MuiFormGroup-root.roteirizacaoCheckbox .MuiCheckbox-root {
    padding: 4px;
}

.MuiFormGroup-root.roteirizacaoCheckbox .MuiTypography-root {
    font-family: 'GoBold' !important;
}

@media screen and (max-width: 600px){
    .tableRoute { grid-row: 0; grid-column: 5; width: 90vw; height: 40vh; }
    .mapRoute { grid-row: 80; grid-column: 5; width: 90vw; height: 80vh; }
    .mapContainer { width: 90vw !important; height: 80vh!important; }
    .chooseBox {grid-row: 5; grid-column: 8; width: 80vw; height: 60vh; }
    .chooseIcon { grid-row: 3; grid-column: 6; width: 18vw; height: 9vh; }

}

.polygonButton:hover {
    box-shadow: 0.2vh 0.1vw #666;
    -webkit-transform: translateY(0.2vh);
            transform: translateY(0.2vh);
}

.selectButton {
    background-color: #4CAF50;
    border: None;
    border-radius: 5px;
    margin-left: 1em;
    margin-top: 0.3em;
    margin-bottom: 0.3em;
    color: whitesmoke;
}
/* stylelint-disable */
body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif !important;
}

.ant-tabs-ink-bar {
  background-color: green;
}

/* .ant-tabs-nav .ant-tabs-tab:hover {
  color: green;
} */

.ant-tabs-nav .ant-tabs-tab:hover {
  color: green;
}

.ant-tabs-tab-active {
  color: green !important;
  font-weight: 500;
}

.option {
    background-color: white
}

.option:hover {
    background-color: lightgreen
}
