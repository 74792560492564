@import "./colors.css";
@import "./spacing.css";
@import "./typography.css";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,600;1,400&family=Ranchers&display=swap");
/* stylelint-disable */
body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif !important;
}

.ant-tabs-ink-bar {
  background-color: green;
}

/* .ant-tabs-nav .ant-tabs-tab:hover {
  color: green;
} */

.ant-tabs-nav .ant-tabs-tab:hover {
  color: green;
}

.ant-tabs-tab-active {
  color: green !important;
  font-weight: 500;
}
