.spinner {
    font-family: 'Gobold';
    text-align: center;
}

.main-table {
  display: flex;
  width: 90vw;
  margin-left: 4vw;
  margin-right: 9vw;
  margin-top: 4vw;
  /* margin-right: 0.1em; */
  /* margin-left: 0.3em; */
  align-content: center;
  justify-self: center;
  align-self: center;
  justify-content: center;
}

.type-dropdown-tipo {
  position: absolute;
  left: 9.11%;
}

.type-dropdown-tipo select {
  padding: 10px;
  width: 100px;
  height: 40px;
  border: none;
  font-family: 'Montserrat';
  font-size: 13px;
  border: 1px solid black;
  background-color: rgb(240, 242, 245);
  box-shadow:0 0 7px gray;
}

.type-dropdown-origem select {
  padding: 10px;
  width: 100px;
  height: 40px;
  border: none;
  font-family: 'Montserrat';
  font-size: 13px;
  border: 1px solid black;
  background-color: rgb(240, 242, 245);
  box-shadow:0 0 7px gray;
}

.type-dropdown-origem {
  position: absolute;
  left: 23.5%;
}

.dropdown-trucker select {
  font-family: 'Montserrat';
  font-size: 12px;
  border: 1px solid black;
  background-color: rgb(240, 242, 245);
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 150px;
  height: 45px;
  border: none;
  font-family: 'Montserrat';
  font-size: 11px;
  border: 2px solid black;
  background-color: rgb(40, 167, 69);
  position: relative;
  left: 75.80%;
  /* cursor: pointer; */
  color: white;
  box-shadow:0 0 7px gray;
  transition: 0.8s;
  overflow: hidden;
  cursor: default;
}

.unbutton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 120px;
  height: 45px;
  border: none;
  font-family: 'Montserrat';
  font-size: 11px;
  border: 2px solid black;
  background-color: rgb(167, 40, 40);
  position: absolute;
  left: 8.80%;
  margin-top: 0.5em;
  /* cursor: pointer; */
  color: white;
  box-shadow:0 0 7px gray;
  transition: 0.8s;
  overflow: hidden;
  cursor: default;
}

.input[type="checkbox"] {
  height: 10px;
  width: 10px;
}


.simple-table {
    display: grid;
    margin: auto;
    border-radius: 5px;
    /* border: 5px solid black; */
    overflow: hidden;
}

.simple-table .table-head {
  margin: 0;
  font-size: 0.8em;
  font-weight: bold;
  color: white;
  font-family: 'Montserrat';
  background-color: rgb(40, 167, 69);
  text-align: center;
  /* border: 0.1em solid black; */
  /* width: auto; */
  padding: 0.1em;
}

.simple-table .teste {
  margin: 0;
  font-size: 0.8em;
  font-weight: bold;
  color: white;
  font-family: 'Montserrat';
  background-color: rgb(40, 167, 69);
  text-align: center;
  position: '-webkit-sticky';
  position: 'sticky';
  /* border: 0.1em solid black; */
  /* width: auto; */
  padding: 0.1em;
}


.simple-table .row-value {
  padding: 0.6em;
  font-family: 'Montserrat';
  text-align: center;
  border: 0.1em solid lightgray;
  font-size: 0.7em;
  background-color: rgb(240, 242, 245)
}

input[type="checkbox"] {
  background-color: rgb(40, 167, 69);
  padding-right: 6px;
}

.react-tabs {
    -webkit-tap-highlight-color: transparent;
  }
  
  .react-tabs__tab-list {
    border-bottom: 1px solid #aaa;
    margin: 0 0 10px;
    padding: 0;
  }
  
  .react-tabs__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
    font-family: 'Montserrat';
  }
  
  .react-tabs__tab--selected {
    background: #fff;
    border-color: #aaa;
    color: black;
    border-radius: 5px 5px 0 0;
  }
  
  .react-tabs__tab--disabled {
    color: GrayText;
    cursor: default;
  }
  
  .react-tabs__tab:focus {
    box-shadow: 0 0 5px hsl(208, 99%, 50%);
    border-color: hsl(208, 99%, 50%);
    outline: none;
  }
  
  .react-tabs__tab:focus:after {
    content: "";
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    background: #fff;
  }
  
  .react-tabs__tab-panel {
    display: none;
  }
  
  .react-tabs__tab-panel--selected {
    display: block;
  }
  